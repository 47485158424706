import axios from 'axios';
import { config } from '../../config/config';

export const RECEIVED_CUSTOMERS = 'RECEIVED_CUSTOMERS';
export const RECEIVING_CUSTOMERS = 'RECEIVING_CUSTOMERS';
export const RECEIVED_CUSTOMER_PARAMS = 'RECEIVED_CUSTOMER_PARAMS';
export const RECEIVING_CUSTOMER_PARAMS = 'RECEIVING_CUSTOMER_PARAMS';
export const RECEIVED_ERROR_RESPONSE = 'RECEIVED_ERROR_RESPONSE';

export function getCustomers(request) {
    return (dispatch) => {
        dispatch(receivingCustomers());
        request.rpcQueue = config.rpcQueue;
        request.menu = "customer";
        request.method = "getCustomers";

        ////console.log(request);

        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };
        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                let results = JSON.parse(data.result);
                ////console.log(results);
                if (results.v_field39 === "000") {
                    dispatch(receivedCustomers(results));
                } else {
                    dispatch(receivedErrorResponse(results));
                }
            }).catch((error) => {
                ////console.log(error)
                dispatch(receivedErrorResponse({
                    v_field39: "057",
                    v_field48: error.message,
                }));
            });
    };
}


export function updateCustomerData(request, user) {
    return (dispatch) => {
        dispatch(receivingCustomers());

        request.rpcQueue = config.rpcQueue;
        request.menu = "customer";
        request.method = "updateCustomer";
        request.created_by = user.username;
        request.location = user.locationData;

        ////console.log(request);

        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };
        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (res) {

                ////console.log(res);
                let results = JSON.parse(res.result);
                if (results.v_field39 === "000") {
                    dispatch(receivedCustomers(results));
                } else {
                    dispatch(receivedErrorResponse());
                }
            }).catch((error) => {
                ////console.log(error)
                dispatch(receivedErrorResponse());
            });

    };
}

export function updateCustomer(request, idFile, user) {
    return (dispatch) => {
        dispatch(receivingCustomers());

        let formData = new FormData();

        formData.append('idFile', idFile);

        request.rpcQueue = config.rpcQueue;
        request.menu = "customer";
        request.method = "updateCustomer";
        request.created_by = user.username;
        request.location = user.locationData;

        formData.append('memberData', JSON.stringify(request));

        if (!formData) {
            dispatch(receivedErrorResponse());
        } else {

            fetch(config.remoteUpload + '/memberfile', {
                method: 'POST',
                headers: { "x-api-key": "qyfCv4sBd0fcquNZijY9nhN7szU4a7KS" },
                body: formData
            }).then(response => response.json()).then(function (fileUploadResponse) {

                ////console.log(fileUploadResponse);
                let results = JSON.parse(fileUploadResponse.result);
                ////console.log(results);
                if (results.v_field39 === "000") {
                    dispatch(receivedCustomers(results));
                } else {
                    dispatch(receivedErrorResponse());
                }
            }).catch((error) => {
                ////console.log(error)
                dispatch(receivedErrorResponse());
            });

        }
    };
}


export function getCustomerParams(request){
    return (dispatch) => {
        dispatch(receivingCustomerParams());

        request.rpcQueue = config.rpcQueue;
        request.menu = "customer";
        request.method = "getCustomerParams";

        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };

        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (res) {

                let results = JSON.parse(res.result);
                if (results.v_field39 === "000") {
                    dispatch(receivedCustomerParams(results));
                } else {
                    dispatch(receivedErrorResponse());
                }
            }).catch((error) => {
                ////console.log(error)
                dispatch(receivedErrorResponse());
            });

    };
}
export function receivedCustomers(payload) {
    return {
        type: RECEIVED_CUSTOMERS,
        payload
    }
}

export function receivingCustomers() {
    return {
        type: RECEIVING_CUSTOMERS
    }
}

export function receivedCustomerParams(payload) {
    return {
        type: RECEIVED_CUSTOMER_PARAMS,
        payload
    }
}

export function receivingCustomerParams() {
    return {
        type: RECEIVING_CUSTOMER_PARAMS
    }
}


export function receivedErrorResponse() {
    return {
        type: RECEIVED_ERROR_RESPONSE
    }
}