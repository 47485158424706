import React, { useState } from 'react';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Menu,
    MenuItem,
    TablePagination,
    CircularProgress
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { format, parse } from 'date-fns';

const MuiReusableTable = ({ columns, rows, table_tile, table_size, table_error, table_actions, pagination, isLoading }) => {
   
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [currentRowIndex, setCurrentRowIndex] = useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(pagination.rowsPerPage);

    const handleClick = (event, row, rowIndex) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(row);
        setCurrentRowIndex(rowIndex);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
        setCurrentRowIndex(null);
    };

    const handleMenuItemClick = (method) => {
        if (currentRow !== null && currentRowIndex !== null && typeof method === 'function') {
            method(currentRow, currentRowIndex);
        }
        handleClose();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const formatDate = (dateString) => {
        //////console.log(dateString);
        const date = new Date(dateString);
        return format(date, 'dd-MMM-yyyy');
    };

    const formatNumber = (number) => {
        return new Intl.NumberFormat().format(number);
    };

    const formatBoolean = (bool) => {
        return bool ? 'Yes' : 'No';
    };

    const formatCount = (value) => {
        if (value)
            return new Intl.NumberFormat().format(value.length);
        return 0;
    };

    return (
        <>
            {isLoading &&
                <CircularProgress />
            }
            {table_error &&
                <Typography variant="h6" component="div" sx={{ padding: 2, color: "error.main" }}>
                    {table_error}
                </Typography>
            }
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="group member table" size={table_size}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(pagination.showPagination
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row, rowIndex) => (

                            <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                            {column.type === 'date' ? formatDate(value) :
                                                column.type === 'number' ? formatNumber(value) :
                                                    column.type === 'boolean' ? formatBoolean(value) :
                                                        column.type === 'count' ? formatCount(value) :
                                                            value}
                                        </TableCell>
                                    );
                                })}
                                {table_actions &&
                                    <TableCell align="right" style={{ width: 5 }}>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={(event) => handleClick(event, row, rowIndex)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            {table_actions.map((item, index) => (
                                                <MenuItem
                                                    key={index}
                                                    onClick={() => handleMenuItemClick(item.method)}
                                                >
                                                    {item.menuName}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </TableCell>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {pagination.showPagination &&
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            </TableContainer>
        </>
    );
}

export default MuiReusableTable;
