import React, { useEffect, useState, useCallback } from 'react';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { DialogActions, DialogContentText, DialogContent, Dialog, Paper, DialogTitle, Button, Stack } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import MuiReusableTable from '../../MuiReusableTable'
import { useSelector, useDispatch } from 'react-redux';
import ReportItem from './ReportForm/ReportItem';
import { config } from '../../../config/config'
import { getReports, updateReportStatus } from '../../../redux/actions/reports'
import NoDataFound from '../../NoDataFound'
import useJumboAuth from "@jumbo/hooks/useJumboAuth";


const ReportsList = ({ reports_params, searchCreteria }) => {

    const { authUser } = useJumboAuth();

    const dispatch = useDispatch();
    const [isReportModalOpen, setIsReportModalOpen] = React.useState(false);
    const [showRequestLink, setShowRequestLink] = React.useState(false);
    const [showAwaiting, setShowAwaiting] = React.useState(false);
    const [selectedReport, setSelectedReport] = React.useState({
        canEdit: true,
        canUpdateStatus: false,
        report_id: null,
        report_name: '',
        report_start_date: '',
        report_end_date: '',
        org_id: '',
        user_mobile: '',
        org_physical_address: '',
        organisation_name: '',
        licence_no: authUser ? authUser.licence_no : '',
        report_file: '',
        status: 'PENDING',
        report_file_name: '',
        reportFileURL: '',
        link_status: ''
    });

    const reports_data = useSelector(state => state.reports.reports_data);
    const isReceivingReports = useSelector(state => state.reports.isReceivingReports);

    useEffect(() => {
        if (reports_params && !isReceivingReports, authUser) {
            let reportRequest = {
                user_email: authUser.user_email,
                user_id: authUser.u_user_id,
                role: authUser.role,
                org_id: authUser.selectedOrgID,
                created_by: authUser.u_user_id,
                location: authUser.locationData
            };
            dispatch(getReports(reportRequest));//fetch available reports
        }
    }, [dispatch, reports_params, authUser]);

    ////console.log(reports_data)
    const reports = reports_data?.v_reports;
    const my_orgs = reports_params?.v_my_orgs;

    const handleAwaitingClose = () => {
        setShowAwaiting(false);
    }

    const handleRequestLinkClose = () => {
        setShowRequestLink(false);
    }

    const handleRequestLink = () => {
        let reportRequest = {
            user_email: authUser.user_email,
            user_id: authUser.u_user_id,
            role: authUser.role,
            report_id: selectedReport.report_id,
            action: 'GET_REPORT',
            created_by: authUser.u_user_id,
            location: authUser.locationData
        };
        dispatch(updateReportStatus(reportRequest, authUser));//fetch available reports
        setShowRequestLink(false);
    }

    const handleReportCloseModal = () => {
        setIsReportModalOpen(false)
    }
    const handleViewReport = (row, index) => {
        setSelectedReport({
            canEdit: row.canEdit == "false" ? false : true,
            canUpdateStatus: row.canUpdateStatus == "false" ? false : true,
            report_id: row.report_id,
            report_name: row.report_name,
            report_start_date: row.report_start_date,
            report_end_date: row.report_end_date,
            org_id: row.org_id,
            user_mobile: row.user_mobile,
            org_physical_address: row.org_physical_address,
            organisation_name: row.organisation_name,
            licence_no: row.licence_no,
            report_file: row.report_file ? config.base_url + row.report_file.replace('/srv/uploads', "/files") : '',
            reportFileURL: row.report_file ? config.base_url + row.report_file.replace('/srv/uploads', "/files") : '',
            status: row.status,
            report_file_name: row.report_file ? row.report_file.split('/').pop() : '',
            posible_actions: row.posible_actions,
            link_status: row.link_status,
        });

        switch (row.link_status) {
            case 'UNLINKED':
                setShowRequestLink(true);
                break;
            case 'AWAITING APPROVAL':
                setShowAwaiting(true);
                break;
            default:
                //console.log(row);
                setIsReportModalOpen(true);
                break;
        }


    }

    const handleAddReport = () => {
        setSelectedReport({
            canEdit: true,
            canUpdateStatus: false,
            report_id: null,
            report_name: '',
            report_start_date: '',
            report_end_date: '',
            org_id: '',
            user_mobile: '',
            org_physical_address: '',
            organisation_name: '',
            licence_no: authUser.licence_no,
            report_file: '',
            status: '',
            report_file_name: '',
            reportFileURL: '',
            link_status: '',
        });

        setIsReportModalOpen(true);
    }
    const columns = [
        { id: 'report_id', label: 'Report ID', minWidth: 50, type: 'string' },
        { id: 'report_name', label: 'Report Name', minWidth: 130, type: 'string' },
        { id: 'report_start_date', label: 'Start Date', minWidth: 120, type: 'date' },
        { id: 'report_end_date', label: 'End Date', minWidth: 120, type: 'date' },
        { id: 'organisation_name', label: 'Organisation', minWidth: 130, type: 'string' },
        { id: 'report_file', label: 'Report File', minWidth: 100, type: 'string' },
        { id: 'created_by', label: 'Prepared By', minWidth: 130, type: 'string' },
        { id: 'licence_number', label: 'Licence Number', minWidth: 100, type: 'string' },
        { id: 'created_on', label: 'Created On', minWidth: 120, type: 'date' },
        { id: 'status', label: 'Report Status', minWidth: 70, type: 'string' },
        ...(authUser.role == 'report-client' ? [{ id: 'link_status', label: 'Link Status', minWidth: 70, type: 'string' }] : []),
    ];

    return (
        <JumboDemoCard title={''} wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>


            <Paper sx={{ width: '100%', overflow: 'hidden', m: 2 }}>

                {authUser && authUser.role === "report-mng" &&
                    <Button
                        variant="outlined"
                        size="large"
                        sx={{ mb: 3 }}
                        onClick={handleAddReport}
                        startIcon={<PersonAddAltIcon />}
                    >
                        New Report
                    </Button>
                }
                {reports ?

                    (<MuiReusableTable
                        rows={reports || []}
                        columns={columns}
                        table_size="small"
                        table_error={''}
                        isLoading={isReceivingReports}
                        pagination={{
                            showPagination: true,
                            rowsPerPage: 6,
                        }}
                        table_actions={[
                            {
                                menuName: 'View Report',
                                method: (row, index) => handleViewReport(row, index)
                            },
                        ]}
                    />

                    ) :

                    <NoDataFound />
                }

            </Paper>


            <Dialog
                open={showRequestLink}
                onClose={handleRequestLinkClose}
                aria-labelledby="request-link-dialog-title"
                aria-describedby="request-link-dialog-description"
                PaperProps={{
                    style: {
                        height: 'auto',
                        width: '30%',
                        maxWidth: 'none'
                    }
                }}
            >
                <DialogTitle id="request-link-dialog-title">Request Report Linking</DialogTitle>
                <DialogContent>
                    <DialogContentText id="request-link-dialog-description">
                        Do you want to request access to this report?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRequestLinkClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleRequestLink} color="primary" autoFocus>
                        Send Request
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showAwaiting}
                onClose={handleAwaitingClose}
                aria-labelledby="request-link-dialog-title"
                aria-describedby="request-link-dialog-description"
                PaperProps={{
                    style: {
                        height: 'auto',
                        width: '30%',
                        maxWidth: 'none'
                    }
                }}
            >
                <DialogTitle id="request-link-dialog-title">System Alert</DialogTitle>
                <DialogContent>
                    <DialogContentText id="request-link-dialog-description">
                        We are sorry for keeping you waiting. Your request will be approved soon!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAwaitingClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isReportModalOpen}
                onClose={handleReportCloseModal}
                fullWidth
                maxWidth="xl"
                PaperProps={{
                    style: {
                        height: 'auto',
                        width: '70%',
                        maxWidth: 'none'
                    }
                }}
            >
                <DialogTitle>Report Management</DialogTitle>
                <DialogContent>
                    <ReportItem
                        setIsReportModalOpen={setIsReportModalOpen}
                        reports_params={reports_params}
                        initialValues={selectedReport} />
                </DialogContent>
            </Dialog>

        </JumboDemoCard>
    );
};

export default ReportsList;
