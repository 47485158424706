import jwtAxios from "axios";

const jwtAuthAxios = jwtAxios.create({
    baseURL: "https://apis.windeal.co.ke",
    headers: {
        'Content-Type': 'application/json',
        "x-api-key": "qyfCv4sBd0fcquNZijY9nhN7szU4a7KS"
    }
});

jwtAuthAxios.interceptors.response.use(
    res => res,
    err => {
        if(err.response && err.response.data.type === "token-invalid") {
            //todo logout the user
        }
        return Promise.reject(err);
    }
);

export const setAuthToken = (token) => {
    console.log(token);
    if(token) {
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        sessionStorage.setItem('token', token);
    } else {
        delete jwtAuthAxios.defaults.headers.common['Authorization'];
        sessionStorage.removeItem('token');
    }
};

export const getAuthToken = () => {
    return sessionStorage.getItem("token");
};

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default jwtAuthAxios;