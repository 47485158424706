

const authServices = {};

authServices.getCurrentUser = async () => {
    let authUser = localStorage.getItem('authUser');
    console.log(authUser)
    return authUser ? JSON.parse(authUser) : {}
};

export default authServices;