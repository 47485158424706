import axios from 'axios';
import { config } from '../../config/config';


export const RECEIVED_GROUP = 'RECEIVED_GROUP';
export const RECEIVING_GROUP = 'RECEIVING_GROUP';
export const RECEIVED_GROUPS = 'RECEIVED_GROUPS';
export const RECEIVING_GROUPS = 'RECEIVING_GROUPS';
export const RECEIVED_GROUP_PARAMS = 'RECEIVED_GROUP_PARAMS';
export const RECEIVING_GROUPS_PARAMS = 'RECEIVING_GROUPS_PARAMS';
export const RECEIVED_ERROR_RESPONSE = 'RECEIVED_ERROR_RESPONSE';



export function saveGroup(request, uploadFile, user) {
    return (dispatch) => {
        dispatch(receivingGroups());
        if (!uploadFile) {
            dispatch(receivedErrorResponse());
        } else {
            let formData = new FormData();
            formData.append(request.fileType, uploadFile);

            request.rpcQueue = config.rpcQueue;
            request.menu = "customer";
            request.method = "updateGroups";
            request.created_by = user.username;
            request.location = user.locationData;

            formData.append('groupData', JSON.stringify(request));

            if (!formData) {
                dispatch(receivedErrorResponse());
            } else {
                ////console.log("Saving group file and other data");
                fetch(config.remoteUpload + '/groupfile', {
                    method: 'POST',
                    headers: { "x-api-key": "qyfCv4sBd0fcquNZijY9nhN7szU4a7KS" },
                    body: formData
                }).then(response => response.json()).then(function (fileUploadResponse) {
                    let results = JSON.parse(fileUploadResponse.result);
                    ////console.log(results);
                    if (results.v_field39 === "000") {
                        dispatch(receivedGroups(results));
                    } else {
                        dispatch(receivedErrorResponse());
                    }
                }).catch((error) => {
                    ////console.log(error)
                    dispatch(receivedErrorResponse());
                });
            }
        }
    };


}


export function saveGroupData(request, user) {
    return (dispatch) => {
        dispatch(receivingGroups());
        request.rpcQueue = config.rpcQueue;
        request.menu = "customer";
        request.method = "updateGroups";
        request.created_by = user.username;
        request.location = user.locationData;

        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };
        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                //////console.log(result);
                let result = JSON.parse(data.result);
                if (result.v_field39 === "000") {
                    ////console.log(result);
                    dispatch(receivedGroups(result));
                } else {
                    dispatch(receivedErrorResponse(result));
                }
            }).catch((error) => {
                dispatch(receivedErrorResponse({
                    v_field48: "Exception: " + error,
                    v_field39: "330"
                }));
            });
    };
}

export function getGroupParams(request, user) {
    return (dispatch) => {
        dispatch(receivingGroupsParams());
        request.rpcQueue = config.rpcQueue;
        request.menu = "customer";
        request.method = "getGroupParams";
        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };
        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                //////console.log(result);
                let result = JSON.parse(data.result);
                if (result.v_field39 === "000") {
                    dispatch(receivedGroupsParams(result));
                } else {
                    dispatch(receivedErrorResponse(result));
                }
            }).catch((error) => {
                dispatch(receivedErrorResponse({
                    v_field48: "Exception: " + error,
                    v_field39: "330"
                }));
            });
    };
}

export function getGroups(request, user) {
    return (dispatch) => {
        dispatch(receivingGroups());
        request.rpcQueue = config.rpcQueue;
        request.menu = "customer";
        request.method = "getGroups";

        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };

        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                //////console.log(result);
                let result = JSON.parse(data.result);
                if (result.v_field39 === "000") {
                    ////console.log(result);
                    dispatch(receivedGroups(result));
                } else {
                    dispatch(receivedErrorResponse(result));
                }
            }).catch((error) => {
                dispatch(receivedErrorResponse({
                    v_field48: "Exception: " + error,
                    v_field39: "330"
                }));
            });
    };
}


export function receivingGroup() {
    return {
        type: RECEIVING_GROUP
    }
}


export function receivingGroups() {
    return {
        type: RECEIVING_GROUPS
    }
}

export function receivingGroupsParams() {
    return {
        type: RECEIVING_GROUPS_PARAMS
    }
}

export function receivedGroup(payload) {
    return {
        type: RECEIVED_GROUP,
        payload
    }
}

export function receivedGroups(payload) {
    return {
        type: RECEIVED_GROUPS,
        payload
    }
}

export function receivedGroupsParams(payload) {
    return {
        type: RECEIVED_GROUP_PARAMS,
        payload
    }
}

export function receivedErrorResponse() {
    return {
        type: RECEIVED_ERROR_RESPONSE
    }
}