import React from "react";
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import AddCardIcon from '@mui/icons-material/AddCard';

import useJumboAuth from "@jumbo/hooks/useJumboAuth";

const useCustomMenus = () => {
    const { authUser } = useJumboAuth(); // Access authUser from the hook

    return authUser ? [
        {
            label: 'sidebar.menu.applications',
            type: "section",
            children: [
                {
                    uri: "/app/reports/all",
                    label: 'sidebar.menuItem.reports',
                    type: "nav-item",
                    icon: <CreditScoreOutlinedIcon sx={{ fontSize: 20 }} />
                },
                ...(authUser.canViewReportRequests ? [{
                    uri: "/app/reports/requests",
                    label: 'sidebar.menuItem.reportRequests',
                    type: "nav-item",
                    icon: <AddCardIcon sx={{ fontSize: 20 }} />
                }] : []),
                ...(authUser.canViewInvoices ? [{
                    uri: "/app/reports/invoices",
                    label: 'sidebar.menuItem.invoices',
                    type: "nav-item",
                    icon: <SourceOutlinedIcon sx={{ fontSize: 20 }} />
                }] : []),
            ]
        },
        {
            label: 'sidebar.menu.admin',
            type: "section",
            children: [
                {
                    uri: "/app/admin/users",
                    label: 'sidebar.menuItem.users',
                    type: "nav-item",
                    icon: <ManageAccountsOutlinedIcon sx={{ fontSize: 20 }} />
                },
                {
                    uri: "/app/admin/orgs",
                    label: 'sidebar.menuItem.orgs',
                    type: "nav-item",
                    icon: <BadgeOutlinedIcon sx={{ fontSize: 20 }} />
                }
            ]
        },
    ] : [];
};

export default useCustomMenus;