import React from "react";
import { Navigate } from "react-router-dom";
import dashboardRoutes from "./dashboardRoutes";
import appsRoutes from "./appsRoutes";
import Login from "../pages/auth-pages/login";
import Signup from "../pages/auth-pages/signup";
import Page from "@jumbo/shared/Page";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Navigate to={"/app/reports/all"} />
    },
    //...dashboardRoutes,
    ...appsRoutes,
    //...adminRoutes
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        path: "/user/login",
        element: <Page component={Login} layout={"solo-page"} disableSmLogin={true} />
    },
    {
        path: "/user/signup",
        element: <Page component={Signup} layout={"solo-page"} disableSmLogin={true} />
    },
];

const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };
