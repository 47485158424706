import React from 'react';
import { Box, Typography, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const Invoice = ({ invoice }) => {

    //console.log(invoice);
    const { invoiceNumber, date, dueDate, items, customer, company, totalAmount } = invoice;

    return (
        <Paper sx={{ p: 3 }}>
            <Box mb={4}>
                <Typography variant="h4" gutterBottom>
                    Invoice
                </Typography>
                <Typography variant="subtitle1">Invoice #: {invoiceNumber}</Typography>
                <Typography variant="subtitle1">Date: {date}</Typography>
                <Typography variant="subtitle1">Due Date: {dueDate}</Typography>
            </Box>

            <Grid container spacing={2} mb={4}>
                <Grid item xs={6}>
                    <Typography variant="h6">From:</Typography>
                    <Typography>The Report DB</Typography>
                    <Typography>3493 HP Kisima</Typography>
                    <Typography>trd.info@trd.com</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6">Bill To:</Typography>
                    <Typography>{company.name}</Typography>
                    <Typography>{company.address}</Typography>
                    <Typography>info@company.com</Typography>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">Price</TableCell>
                            <TableCell align="right">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.description}</TableCell>
                                <TableCell align="right">{item.quantity}</TableCell>
                                <TableCell align="right">${item.price.toFixed(2)}</TableCell>
                                <TableCell align="right">${(item.quantity * item.price).toFixed(2)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box mt={4} textAlign="right">
                <Typography variant="h6">Total Amount: ${totalAmount.toFixed(2)}</Typography>
            </Box>
        </Paper>
    );
};

export default Invoice;
