import { config } from '../../config/config';

export const RECEIVED_REPORTS = 'RECEIVED_REPORTS';
export const RECEIVED_REPORT_REQUESTS = 'RECEIVED_REPORT_REQUESTS';
export const RECEIVED_REPORTS_PARAMETERS = 'RECEIVED_REPORTS_PARAMETERS';
export const RECEIVING_REPORTS = 'RECEIVING_REPORTS';
export const RECEIVING_REPORT_REQUESTS = 'RECEIVING_REPORT_REQUESTS';
export const RECEIVING_REPORTS_PARAMETERS = 'RECEIVING_REPORTS_PARAMETERS';
export const RECEIVED_ERROR_RESPONSE = 'RECEIVED_ERROR_RESPONSE';


export function receivedReportRequests(payload) {
    return {
        type: RECEIVED_REPORT_REQUESTS,
        payload
    }
}

export function receivingReportRequests() {
    return {
        type: RECEIVING_REPORT_REQUESTS
    }
}


export function receivedReports(payload) {
    return {
        type: RECEIVED_REPORTS,
        payload
    }
}

export function receivingReports() {
    return {
        type: RECEIVING_REPORTS
    }
}

export function receivedReportsParams(payload) {
    return {
        type: RECEIVED_REPORTS_PARAMETERS,
        payload
    }
}

export function receivingReportsParams() {
    return {
        type: RECEIVING_REPORTS_PARAMETERS
    }
}


export function receivedErrorResponse() {
    return {
        type: RECEIVED_ERROR_RESPONSE
    }
}

export function updateReport(request, report_file, user) {
    return (dispatch) => {
        dispatch(receivingReports());

        let formData = new FormData();

        formData.append('TWReportFile', report_file);

        request.rpcQueue = config.rpcQueue;
        request.menu = "reports";
        request.method = "updateReport";
        request.created_by = user.username;
        request.location = user.locationData;

        formData.append('reportData', JSON.stringify(request));

        if (!formData) {
            dispatch(receivedErrorResponse());
        } else {

            fetch(config.remoteUpload + '/reportsUpload', {
                method: 'POST',
                headers: { "x-api-key": "qyfCv4sBd0fcquNZijY9nhN7szU4a7KS" },
                body: formData
            }).then(response => response.json()).then(function (fileUploadResponse) {

                ////console.log(fileUploadResponse);
                let results = JSON.parse(fileUploadResponse.result);
                ////console.log(results);
                if (results.v_field39 === "000") {
                    dispatch(receivedReports(results));
                } else {
                    dispatch(receivedErrorResponse());
                }
            }).catch((error) => {
                ////console.log(error)
                dispatch(receivedErrorResponse());
            });

        }
    };
}


export function updateReportStatus(request, user) {
    return (dispatch) => {
        if (/_REQUEST/.test(request.action)) {
            dispatch(receivingReportRequests());
        } else {
            dispatch(receivingReports());
        }
        
        request.rpcQueue = config.rpcQueue;
        request.menu = "reports";
        request.method = "updateReport";
        request.created_by = user.username;
        request.location = user.locationData;

        ////console.log(request);

        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };
        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                let results = JSON.parse(data.result);
                ////console.log(results);
                if (results.v_field39 === "000") {

                    if (/_REQUEST/.test(request.action)) {
                        dispatch(receivedReportRequests(results));
                    } else {
                        dispatch(receivedReports(results));
                    }

                    
                } else {
                    dispatch(receivedErrorResponse(results));
                }
            }).catch((error) => {
                ////console.log(error)
                dispatch(receivedErrorResponse({
                    v_field39: "057",
                    v_field48: error.message,
                }));
            });
    };
}


export function getReports(request) {
    return (dispatch) => {
        dispatch(receivingReports());
        request.rpcQueue = config.rpcQueue;
        request.menu = "reports";
        request.method = "getReports";

        ////console.log(request);

        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };
        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                let results = JSON.parse(data.result);
                ////console.log(results);
                if (results.v_field39 === "000") {
                    dispatch(receivedReports(results));
                } else {
                    dispatch(receivedErrorResponse(results));
                }
            }).catch((error) => {
                ////console.log(error)
                dispatch(receivedErrorResponse({
                    v_field39: "057",
                    v_field48: error.message,
                }));
            });
    };
}

export function getReportRequests(request) {
    return (dispatch) => {
        dispatch(receivingReportRequests());
        request.rpcQueue = config.rpcQueue;
        request.menu = "reports";
        request.method = "getReportRequests";

        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };
        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                let results = JSON.parse(data.result);
                ////console.log(results);
                if (results.v_field39 === "000") {
                    dispatch(receivedReportRequests(results));
                } else {
                    dispatch(receivedErrorResponse(results));
                }
            }).catch((error) => {
                ////console.log(error)
                dispatch(receivedErrorResponse({
                    v_field39: "057",
                    v_field48: error.message,
                }));
            });
    };
}

export function getReportsParams(request, authUser) {
    return (dispatch) => {
        dispatch(receivingReportsParams());
        request.rpcQueue = config.rpcQueue;
        request.menu = "reports";
        request.method = "getReportsParams";

        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };
        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                let results = JSON.parse(data.result);
                ////console.log(results);
                if (results.v_field39 === "000") {
                    dispatch(receivedReportsParams(results));
                } else {
                    dispatch(receivedErrorResponse(results));
                }
            }).catch((error) => {
                ////console.log(error)
                dispatch(receivedErrorResponse({
                    v_field39: "057",
                    v_field48: error.message,
                }));
            });
    };
}
