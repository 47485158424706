import React from "react";
import Reports from "../pages/reports/Reports"
import ReportRequests from "../pages/report-requests/ReportRequests"
import ComingSoon from "../pages/ComingSoon"

import Page from "@jumbo/shared/Page";


const appsRoutes = [
    {
        path: [
            "/app/reports/:category",
            "/app/reports/:category/:id",
        ],
        element: <Page component={Reports} />,
    },
    {
        path: [
            "/app/reports/requests",
            "/app/reports/requests/:id",
        ],
        element: <Page component={ReportRequests} />,
    },
    {
        path: [
            "/app/reports/invoices",
            "/app/reports/invoices/:id",
        ],
        element: <Page component={ComingSoon} />,
    },
    {
        path: [
            "/app/admin/users",
            "/app/admin/users/:id",
        ],
        element: <Page component={ComingSoon} />,
    },
    {
        path: [
            "/app/admin/orgs",
            "/app/admin/orgs/:id",
        ],
        element: <Page component={ComingSoon} />,
    }
];

export default appsRoutes;
