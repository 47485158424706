import React, { useEffect, useState, useCallback } from 'react';
import { Stepper, Step, StepLabel, Box } from '@mui/material';
import { PersonalDetails } from './components/PersonalDetails';
import { Organisation } from './components/Organisation';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { signUpUser, verifyOrg, clearOrg, validateLicenceNo } from "../../../../redux/actions/login";
import { useSelector, useDispatch } from 'react-redux';

const steps = [
    { key: 'personalDetails', label: 'User Details', component: PersonalDetails },
    { key: 'organisation', label: 'Stakeholder 3 Org', component: Organisation }
];



const UserForm = ({ setIsUserAddModalOpen }) => {

    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [initialFormData, setInitialFormData] = useState({})
    const [saveMember, setSaveMember] = useState(false);

    const org = useSelector(state => state.login.org);
    const licence_details = useSelector(state => state.login.licence_details);
    const signUpError = useSelector(state => state.login.errorMessage);
    const isFetching = useSelector(state => state.login.isFetching);
    const isValidatingLicence = useSelector(state => state.login.isValidatingLicence);

  
    const verifyUserOrg = (org_id) => {
        const request = {
            org_id: org_id,
            action: 'GET_ORG'
        };
        ////console.log(request);
        dispatch(verifyOrg(request));
    }

    const validateLicence = (licence_no) => {
        const request = {
            licence_no: licence_no,
            action: 'VALIDATE_LICENCE'
        };
        console.log(request);
       dispatch(validateLicenceNo(request));
    }

    const saveUserManagerData = (updatedFormData) => {

        ////console.log(updatedFormData);
        const request = {
            dateofbirth: updatedFormData.personalDetails.dateofbirth,
            firstname: updatedFormData.personalDetails.firstname,
            gender: updatedFormData.personalDetails.gender,
            id_file_name: updatedFormData.personalDetails.id_file_name.name,
            id_idFile: updatedFormData.personalDetails.id_idFile.name,
            idnumber: updatedFormData.personalDetails.idnumber,
            idtype: updatedFormData.personalDetails.idtype,
            lastname: updatedFormData.personalDetails.lastname,
            mobilenumber: updatedFormData.personalDetails.mobilenumber,
            role: updatedFormData.personalDetails.role,
            user_email: updatedFormData.personalDetails.user_email,
            licence_no: updatedFormData.personalDetails.licence_number,
            org_id: updatedFormData.personalDetails.org_number === '' ? null : updatedFormData.personalDetails.org_number,
            action: 'SAVE_RM'
        };

        //console.log(request);
        dispatch(signUpUser(request, updatedFormData.personalDetails.id_idFile));
        setIsUserAddModalOpen(false);
    }


    const sendClearOrg = () => {
        dispatch(clearOrg())
    }
    const saveMemberData = (updatedFormData) => {

        ////console.log(updatedFormData);
        const request = {
            org_description: updatedFormData.organisationDetails.org_description,
            org_emailaddress: updatedFormData.organisationDetails.org_emailaddress,
            org_mobilenumber: updatedFormData.organisationDetails.org_mobilenumber,
            org_physical_address: updatedFormData.organisationDetails.org_physical_address,
            organisation_name: updatedFormData.organisationDetails.organisation_name,
            organisation_pin: updatedFormData.organisationDetails.organisation_pin,
            dateofbirth: updatedFormData.personalDetails.dateofbirth,
            firstname: updatedFormData.personalDetails.firstname,
            gender: updatedFormData.personalDetails.gender,
            id_file_name: updatedFormData.personalDetails.id_file_name.name,
            id_idFile: updatedFormData.personalDetails.id_idFile.name,
            idnumber: updatedFormData.personalDetails.idnumber,
            idtype: updatedFormData.personalDetails.idtype,
            lastname: updatedFormData.personalDetails.lastname,
            mobilenumber: updatedFormData.personalDetails.mobilenumber,
            role: updatedFormData.personalDetails.role,
            licence_no: updatedFormData.personalDetails.licence_number,
            user_email: updatedFormData.personalDetails.user_email,
            action: 'SAVE'
        };


        //console.log(request);
        dispatch(signUpUser(request, updatedFormData.personalDetails.id_idFile));
        setIsUserAddModalOpen(false);
    }

    const handleNext = (newData, label) => {

        const updatedFormData = {
            ...formData,
            ...newData,
        };
        let role = updatedFormData.personalDetails.role;
        //console.log(label, role)
        setFormData(updatedFormData);

        if (label !== "next") {
            if (role !== "sa-system") {
                saveUserManagerData(updatedFormData);
            } else {
                saveMemberData(updatedFormData);
            }

        } else {
            let objectIndex = steps[activeStep + 1].key;
            setInitialFormData(formData[objectIndex] || {});
            setActiveStep(prevStep => prevStep + 1);
        }

    };

    const handleBack = (newData) => {

        ////console.log(newData);
        setFormData(prevFormData => ({
            ...prevFormData,
            ...newData, // Merge new data into the existing formData
        }));

        let objectIndex = steps[activeStep - 1].key;
        setInitialFormData(formData[objectIndex]);
        setActiveStep(prevStep => prevStep - 1);
    };

    const StepComponent = steps[activeStep].component;

    return (
        <Box>
            <Stepper activeStep={activeStep}>
                {steps.map(step => (
                    <Step key={step.key}>
                        <StepLabel>{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box my={2}>
                <JumboDemoCard title={'New User'} wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>
                    <StepComponent
                        verifyUserOrg={verifyUserOrg}
                        org={org}
                        validateLicenceNo={validateLicence}
                        licence_details={licence_details}
                        isFetching={isFetching}
                        isValidatingLicence={isValidatingLicence}
                        activeStep={activeStep}
                        initialFormData={initialFormData}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        clearOrg={sendClearOrg}
                        noOfSteps={steps.length}
                    />
                </JumboDemoCard>
            </Box>
        </Box>
    );
};

export default UserForm;
