import React from 'react';
import { Box, Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';

const ComingSoon = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            textAlign="center"
            bgcolor="#f5f5f5"
            p={4}
        >
            <ConstructionIcon style={{ fontSize: 80, color: '#ff9800' }} />
            <Typography variant="h3" gutterBottom>
                Coming Soon
            </Typography>
           
        </Box>
    );
};

export default ComingSoon;
