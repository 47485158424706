import axios from 'axios';
import { config } from '../../config/config';

export const RECEIVED_MEMBERS = 'RECEIVED_MEMBERS';
export const RECEIVING_MEMBERS = 'RECEIVING_MEMBERS';
export const RECEIVED_ERROR_RESPONSE = 'RECEIVED_ERROR_RESPONSE';

export function getMembers(request, user) {
    return (dispatch) => {
        dispatch(receivingMembers());

        request.search_creteria= {
            group_id: request.group_id
        };

        request.rpcQueue = config.rpcQueue;
        request.menu = "customer";
        request.method = "getCustomers";
        request.created_by = user.username;
        request.location = user.locationData;

        ////console.log(request);

        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };
        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                let results = JSON.parse(data.result);
                ////console.log(results);
                if (results.v_field39 === "000") {
                    dispatch(receivedMembers(results));
                } else {
                    dispatch(receivedErrorResponse(results));
                }
            }).catch((error) => {
                ////console.log(error)
                dispatch(receivedErrorResponse({
                    v_field39: "057",
                    v_field48: error.message,
                }));
            });
    };
}


export function updateMemberData(request, user) {
    return (dispatch) => {
        dispatch(receivingMembers());

        request.rpcQueue = config.rpcQueue;
        request.menu = "customer";
        request.method = "updateCustomer";
        request.created_by = user.username;
        request.location = user.locationData;

        ////console.log(request);

        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };
        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (res) {

                ////console.log(res);
                let results = JSON.parse(res.result);
                if (results.v_field39 === "000") {
                    dispatch(receivedMembers(results));
                } else {
                    dispatch(receivedErrorResponse());
                }
            }).catch((error) => {
                ////console.log(error)
                dispatch(receivedErrorResponse());
            });

    };
}

export function updateMember(request, idFile, user) {
    return (dispatch) => {
        dispatch(receivingMembers());

        let formData = new FormData();

        formData.append('idFile', idFile);

        request.rpcQueue = config.rpcQueue;
        request.menu = "customer";
        request.method = "updateCustomer";
        request.created_by = user.username;
        request.location = user.locationData;

        formData.append('memberData', JSON.stringify(request));

        if (!formData) {
            dispatch(receivedErrorResponse());
        } else {

            fetch(config.remoteUpload + '/memberfile', {
                method: 'POST',
                headers: { "x-api-key": "qyfCv4sBd0fcquNZijY9nhN7szU4a7KS" },
                body: formData
            }).then(response => response.json()).then(function (fileUploadResponse) {

                ////console.log(fileUploadResponse);
                let results = JSON.parse(fileUploadResponse.result);
                ////console.log(results);
                if (results.v_field39 === "000") {
                    dispatch(receivedMembers(results));
                } else {
                    dispatch(receivedErrorResponse());
                }
            }).catch((error) => {
                ////console.log(error)
                dispatch(receivedErrorResponse());
            });

        }
    };
}

export function receivedMembers(payload) {
    return {
        type: RECEIVED_MEMBERS,
        payload
    }
}

export function receivingMembers() {
    return {
        type: RECEIVING_MEMBERS
    }
}

export function receivedErrorResponse() {
    return {
        type: RECEIVED_ERROR_RESPONSE
    }
}