import { config } from '../../config/config';
import axios from 'axios';
import sha512 from "js-sha512";
import { jwtDecode } from 'jwt-decode';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const RECEIVE_SIGNUP = 'RECEIVE_SIGNUP';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'LOGOUT_FAILURE';
export const VERIFY_FAILURE = 'VERIFY_FAILURE';
export const CLEAR_ORG = 'CLEAR_ORG';
export const LICENCE_VALIDATION_FAILURE = 'LICENCE_VALIDATION_FAILURE';
export const LICENCE_VALIDATION_SUCCESS= 'LICENCE_VALIDATION_SUCCESS';
export const LICENCE_VALIDATION_REQUEST= 'LICENCE_VALIDATION_REQUEST';


function requestClearOrg() {
    return {
        type: CLEAR_ORG,
    };
}
function requestSignUp() {
    return {
        type: SIGNUP_REQUEST,
    };
}
function receiveSignUp(payload) {
    return {
        type: RECEIVE_SIGNUP,
        payload,
    };
}
function signUpError(payload) {
    return {
        type: SIGNUP_FAILED,
        payload,
    };
}

function requestVerification() {
    return {
        type: VERIFY_REQUEST,
    };
}

export function receiveOrgVerified(payload) {
    return {
        type: VERIFY_SUCCESS,
        payload,
    };
}

export function receiveVerifyFailure(payload) {
    return {
        type: VERIFY_FAILURE,
        payload,
    };
}

function requestValidatingLicence() {
    return {
        type: LICENCE_VALIDATION_REQUEST,
    };
}

export function receiveValidatingLicence(payload) {
    return {
        type: LICENCE_VALIDATION_SUCCESS,
        payload,
    };
}

export function receiveValidatingLicenceFailure(payload) {
    return {
        type: LICENCE_VALIDATION_FAILURE,
        payload,
    };
}

function requestLogin() {
    return {
        type: LOGIN_REQUEST,
    };
}

export function receiveLogin(payload) {
    return {
        type: LOGIN_SUCCESS,
        payload,
    };
}

function loginError(payload) {
    return {
        type: LOGIN_FAILURE,
        payload,
    };
}

function requestLogout() {
    return {
        type: LOGOUT_REQUEST,
    };
}

export function receiveLogout() {
    return {
        type: LOGOUT_SUCCESS,
    };
}

export function clearOrg() {
    return (dispatch) => {
        dispatch(requestClearOrg());
    }
}


export function loginUser(creds) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(requestLogin());

            if (creds.email.length > 0 && creds.password.length > 0) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    const url = config.locationAPI + "latlng=" + position.coords.latitude + "," + position.coords.longitude + "&sensor=true&key=" + config.googleAPIKey;

                    const requestOptions = { method: 'GET' };

                    fetch(url, requestOptions)
                        .then(response => response.json())
                        .then(function (data) {
                            let result = data.results[0];
                            result.coords = {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            }
                            creds.rpcQueue = config.rpcQueue;
                            creds.menu = "userlogin";
                            creds.method = "main";
                            creds.username = creds.email;
                            creds.password = sha512(creds.password);
                            creds.created_by = creds.email;
                            creds.location = {
                                formatted_address: result.formatted_address,
                                coordinates: result.coords
                            };
                            const requestOptions = {
                                method: 'POST',
                                headers: config.headers,
                                body: JSON.stringify(creds)
                            };
                            fetch(config.remote, requestOptions)
                                .then(response => response.json())
                                .then(function (data) {
                                    const token = data.result;
                                    const decodedToken = jwtDecode(token);
                                    if (decodedToken.v_field39 === "000") {
                                        let user_details = decodedToken.user_details;
                                        user_details.locationData = creds.location;
                                        user_details.loginTime = new Date();
                                        dispatch(receiveLogin(user_details));
                                        resolve({ user: user_details, token: token });
                                    } else {
                                        dispatch(loginError(decodedToken));
                                        reject(decodedToken);
                                    }
                                }).catch(err => {
                                    dispatch(loginError(err.response));
                                    reject(err.response);
                                });
                        });
                });
            } else {
                dispatch(loginError('Something was wrong. Try again'));
                reject('Something was wrong. Try again');
            }
        });
    };
}

export function validateLicenceNo(request){
    return (dispatch) => {
        dispatch(requestValidatingLicence());
        request.rpcQueue = config.rpcQueue;
        request.menu = "user";
        request.method = "updateuser";
        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };
        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                const result = JSON.parse(data.result);

                console.log(result);
                if (result.v_field39 == "000") {
                    dispatch(receiveValidatingLicence(result));
                } else {
                    dispatch(receiveValidatingLicenceFailure(result));
                }
            }).catch(err => {
                dispatch(receiveValidatingLicenceFailure({
                    v_field39: "132",
                    v_field48: err.response
                }));
            });
    };
}

export function verifyOrg(request) {
    return (dispatch) => {
        dispatch(requestVerification());
        request.rpcQueue = config.rpcQueue;
        request.menu = "user";
        request.method = "updateuser";
        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };
        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                const result = JSON.parse(data.result);

                ////console.log(result);
                if (result.v_field39 == "000") {
                    dispatch(receiveOrgVerified(result));
                } else {
                    dispatch(receiveVerifyFailure(result));
                }
            }).catch(err => {
                dispatch(receiveVerifyFailure({
                    v_field39: "132",
                    v_field48: err.response
                }));
            });
    };
}

export function changePassword(creds) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(requestLogin());

            creds.rpcQueue = config.rpcQueue;
            creds.menu = "userlogin";
            creds.method = "changePasswordAndLogin";
            creds.username = creds.email;
            creds.password = sha512(creds.cpassword);
            creds.created_by = creds.email;
            const requestOptions = {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify(creds)
            };
            fetch(config.remote, requestOptions)
                .then(response => response.json())
                .then(function (data) {
                    const token = data.result;
                    const decodedToken = jwtDecode(token);
                    if (decodedToken.v_field39 === "000") {
                        let user_details = decodedToken.user_details;
                        user_details.locationData = creds.location;
                        user_details.loginTime = new Date();
                        dispatch(receiveLogin(user_details));
                        resolve({ user: user_details, token: token });
                    } else {
                        dispatch(loginError(decodedToken));
                        resolve(decodedToken);
                    }
                }).catch(err => {
                    dispatch(loginError(err.response));
                });
        })

    };
}


export function signUpUser(request, idFile) {

    return (dispatch) => {
        dispatch(requestSignUp());
        if (request) {
            navigator.geolocation.getCurrentPosition(function (position) {

                const url = config.locationAPI + "latlng=" + position.coords.latitude + "," + position.coords.longitude + "&sensor=true&key=" + config.googleAPIKey;

                const requestOptions = {
                    method: 'GET',
                };

                fetch(url, requestOptions)
                    .then(response => response.json())
                    .then(function (data) {
                        let result = data.results[0];

                        request.location = {
                            formatted_address: result.formatted_address,
                            coordinates: {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            }
                        };
                        request.rpcQueue = config.rpcQueue;
                        request.menu = "user";
                        request.method = "updateuser";

                        const requestOptions = {
                            method: 'POST',
                            headers: config.headers,
                            body: JSON.stringify(request)
                        };

                        fetch(config.remote, requestOptions)
                            .then(response => response.json())
                            .then(function (data) {
                                let results = JSON.parse(data.result);
                                ////console.log(results);
                                if (results.v_field39 === "000") {
                                    dispatch(receiveSignUp(results));
                                } else {
                                    dispatch(signUpError(results));
                                }
                            }).catch((error) => {
                                ////console.log(error)
                                dispatch(signUpError({
                                    v_field39: "057",
                                    v_field48: error.message,
                                }));
                            });
                    });
            });
        }
    }
}

export function logoutUser() {
    return (dispatch) => {
        dispatch(requestLogout());
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        axios.defaults.headers.common['Authorization'] = "";
        dispatch(receiveLogout());
    }
}