import React, { useEffect, useState } from 'react';
import { Card, CardContent, Checkbox, FormControlLabel, Typography, Dialog, DialogTitle, DialogContent } from "@mui/material";
import Link from "@mui/material/Link";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import * as yup from "yup";
import UserForm from './UserForm/UserForm';
import Div from "@jumbo/shared/Div";
import { loginUser, changePassword, receiveToken } from "../../../redux/actions/login";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { useTranslation } from "react-i18next";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

const Login = () => {
    const { t } = useTranslation();
    const [isUserAddModalOpen, setIsUserAddModalOpen] = useState(false);
    const [formValues, setFormValues] = useState({
        email: '',
        password: '',
        new_password: '',
        cpassword: '',
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { setAuthData, setAuthToken } = useJumboAuth();

    //console.log(jumboAuth)
    const user = useSelector(state => state.login.user);
    const loginError = useSelector(state => state.login.errorMessage);
    const isFetching = useSelector(state => state.login.isFetching);

    const v_field39 = loginError?.v_field39;
    const v_field48 = loginError?.v_field48;


    const onSignIn = (email, password, cpassword) => {
        if (v_field39 === "015") {
            dispatch(changePassword({ email, cpassword }));
        } else {
            dispatch(loginUser({ email, password }))
                .then((response) => {
                    console.log(response);
                    if (response && response.user && response.token) {
                        setAuthData(response.user);
                        setAuthToken(response.token); // Set the authentication token
                        navigate("/app/reports/all"); // Redirect after login
                    }
                })
                .catch((error) => {
                   console.log(v_field48)
                });
        }
    };

    const handleUserAddCloseModal = () => {
        setIsUserAddModalOpen(false);
    };

    const startSignUp = () => {
        setIsUserAddModalOpen(true);
    };

    const validationSchema = yup.object({
        email: yup
            .string(t("widgets.validation.emailstring"))
            .email(t("widgets.validation.validemail"))
            .required(t("widgets.validation.emailrequired")),
        password: yup
            .string(t("widgets.validation.passwordstring"))
            .required(t("widgets.validation.passwordrequired")),
        new_password: yup.string().when('v_field39', {
            is: "015",
            then: yup
                .string(t("widgets.validation.newpasswordstring"))
                .min(8, t("widgets.validation.newpasswordlength"))
                .matches(/[A-Z]/, t("widgets.validation.newpasswordupper"))
                .matches(/[a-z]/, t("widgets.validation.newpasswordlower"))
                .matches(/[0-9]/, t("widgets.validation.newpasswordnumber"))
                .matches(/[\W_]/, t("widgets.validation.newpasswordsc"))
                .notOneOf([yup.ref('password')], t("widgets.validation.newpassworddiff"))
                .required(t("widgets.validation.newpasswordrequired")),
            otherwise: yup.string().notRequired(),
        }),
        cpassword: yup.string().when('v_field39', {
            is: "015",
            then: yup
                .string(t("widgets.validation.cpasswordstring"))
                .oneOf([yup.ref('new_password')], t("widgets.validation.marchingcpassword"))
                .required(t("widgets.validation.cpasswordrequired")),
            otherwise: yup.string().notRequired(),
        }),
    });

    return (
        <Div sx={{ width: 720, maxWidth: '100%', margin: 'auto', p: 4 }}>
            <Card sx={{ display: 'flex', minWidth: 0, flexDirection: { xs: 'column', md: 'row' } }}>
                <CardContent
                    sx={{
                        flex: '0 1 200px',
                        position: 'relative',
                        background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/designecologist.jpg`, "640x428")}) no-repeat center`,
                        backgroundSize: 'cover',
                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#0267a0', .65)
                        }
                    }}
                >
                    <Div sx={{ display: 'flex', minWidth: 0, flex: 1, flexDirection: 'column', color: 'common.white', position: 'relative', zIndex: 1, height: '100%' }}>
                        <Div sx={{ mb: 2 }}>
                            <Typography variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>{t('widgets.title.signin')}</Typography>
                        </Div>
                        <Div sx={{ mt: 'auto' }}>
                            <Link href="#" underline="none" sx={{ display: 'inline-flex' }}>
                                <img src={`${ASSET_IMAGES}/logo@2x.png`} alt="Jumbo React" height={42} />
                            </Link>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{ flex: 1, p: 4 }}>
                    <Formik
                        validateOnChange={true}
                        enableReinitialize={true}  // Allow Formik to reinitialize values when they change
                        initialValues={formValues}
                        validationSchema={validationSchema}
                        onSubmit={(data, { setSubmitting }) => {
                            setFormValues(data);  // Store form values in state
                            setSubmitting(true);
                            onSignIn(data.email, data.password, data.cpassword);
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting, values }) => (
                            <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                                {user &&
                                    <Typography color="success.dark" mb={3} sx={{ fontSize: '0.85rem' }}>
                                        {user.v_field48}
                                    </Typography>
                                }
                                <Div sx={{ mt: 1, mb: 3 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label={t("widgets.labels.email")}
                                    />
                                </Div>
                                <Div sx={{ mt: 1, mb: 2 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="password"
                                        label={t("widgets.labels.password")}
                                        type="password"
                                    />
                                </Div>
                                {v_field39 === "015" && (
                                    <>
                                        <Div sx={{ mt: 1, mb: 2 }}>
                                            <JumboTextField
                                                fullWidth
                                                name="new_password"
                                                label={t("widgets.labels.newpassword")}
                                                type="password"
                                            />
                                        </Div>
                                        <Div sx={{ mt: 1, mb: 2 }}>
                                            <JumboTextField
                                                fullWidth
                                                name="cpassword"
                                                label={t("widgets.labels.cpassword")}
                                                type="password"
                                            />
                                        </Div>
                                    </>
                                )}
                                <Div sx={{ mb: 2 }}>
                                    <FormControlLabel control={<Checkbox />} label={t("widgets.labels.rememberme")} />
                                </Div>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isFetching}
                                >
                                    {values.v_field39 === "015" ? t("widgets.labels.changepassword") : t("widgets.labels.signin")}
                                </LoadingButton>
                                {loginError &&
                                    <Typography
                                        color={v_field39 === "015" || v_field39 === '016' ? 'success.dark' : 'error'}
                                        mb={3}
                                        sx={{ fontSize: '0.75rem' }}
                                    >
                                        {v_field39 === "015" || v_field39 === '016' ? v_field48 : "System Error " + v_field39 + ": " + v_field48}
                                    </Typography>
                                }
                                <Typography variant={"body1"}>
                                    {t("widgets.labels.accountexistsqn")}
                                    <Link
                                        onClick={startSignUp}
                                        color={"inherit"}
                                        underline={'none'}
                                        sx={{ cursor: 'pointer', color: 'blue' }}
                                    > {t("widgets.labels.signup")}
                                    </Link>
                                </Typography>
                            </Form>
                        )}
                    </Formik>
                    <Dialog
                        open={isUserAddModalOpen}
                        onClose={handleUserAddCloseModal}
                        fullWidth
                        maxWidth="xl"
                        PaperProps={{
                            style: {
                                height: 'auto',
                                width: '70%',
                                maxWidth: 'none'
                            }
                        }}
                    >
                        <DialogTitle>Sign Up</DialogTitle>
                        <DialogContent>
                            <UserForm setIsUserAddModalOpen={setIsUserAddModalOpen} />
                        </DialogContent>
                    </Dialog>
                </CardContent>
            </Card>
        </Div>
    );
};

export default Login;
