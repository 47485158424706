import {
    RECEIVED_REPORTS,
    RECEIVED_REPORTS_PARAMETERS,
    RECEIVING_REPORTS,
    RECEIVING_REPORTS_PARAMETERS,
    RECEIVED_ERROR_RESPONSE,
    RECEIVED_REPORT_REQUESTS,
    RECEIVING_REPORT_REQUESTS
} from '../actions/reports';

const defaultState = {
    reports_data: {},
    reports_requests: {},
    reports_params: {},
    errorMessage: {},
    isReceiving: false,
    isReceivingReports: false,
    isReceivingReportRequests: false,
};

export default function membersReducer(state = defaultState, action) {
    switch (action.type) {

        case RECEIVED_REPORTS:
            return {
                ...state,
                reports_data: action.payload,
                isReceivingReports: false,
            };
        case RECEIVING_REPORTS:
            return {
                ...state,
                isReceivingReports: true
            };

            case RECEIVED_REPORT_REQUESTS:
            return {
                ...state,
                reports_requests: action.payload,
                isReceivingReportRequests: false,
            };
        case RECEIVING_REPORT_REQUESTS:
            return {
                ...state,
                isReceivingReportRequests: true
            };

        case RECEIVED_REPORTS_PARAMETERS:
            return {
                ...state,
                reports_params: action.payload,
                isReceiving: false,
            };
        case RECEIVING_REPORTS_PARAMETERS:
            return {
                ...state,
                isReceiving: true
            };
        case RECEIVED_ERROR_RESPONSE:
            return {
                ...state,
                isReceiving: false,
                isReceivingReports: false,
                errorMessage: action.payload,
            };
        default:
            return state;
    }
}
