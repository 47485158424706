import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import contactsApp from "./contactsApp";
import dashboard from "./dashboard";
import groupApp from "./groupApp";
import members from "./members";
import customers from "./customers";
import login from "./login";
import reports from "./reports";

const exportReducers = history => {
    return combineReducers({
        router: connectRouter(history),
        contactsApp: contactsApp,
        dashboard: dashboard,
        groupApp: groupApp,
        members: members,
        customers: customers,
        login: login,
        reports: reports,
    });
};

export default exportReducers;
