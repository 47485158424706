import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress, Box } from "@mui/material";
import LoanSummary from "../../../shared/metrics/LoanSummary";
import MemberCount from "../../../shared/widgets/MemberCount";
import LoanSumCard from "../../../shared/widgets/LoanSumCard";
import InArrears from "../../../shared/widgets/InArrears";
import GroupCounterCard from "../../../shared/widgets/GroupCounterCard";
import SavingsSumCard from "../../../shared/widgets/SavingsSumCard";
import Outstanding from "../../../shared/widgets/Outstanding";
import MemberBreakdown from "../../../shared/metrics/MemberBreakdown";
import PortfolioBalance from "../../../shared/metrics/PortfolioBalance";
import LoanOverview from "../../../shared/metrics/LoanOverview";
import { useSelector, useDispatch } from 'react-redux';
import { getDashBoardData } from "../../../redux/actions/dashboard";

const userString = localStorage.getItem('user');
const authUser = userString ? JSON.parse(userString) : undefined;

const MainDashboard = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        if (authUser && !isReceiving) {
            dispatch(getDashBoardData({
                action: "TOTALS"
            }, authUser));
        }

    }, [dispatch, authUser]);


    const dashboardData = useSelector(state => state.dashboard.data.TOTALS);
    const isReceiving = useSelector(state => state.dashboard.isReceiving.TOTALS)

    let summary = {};
    if (dashboardData) {
        summary = dashboardData.v_summary;
    }

    return (
        <>
            {isReceiving.TOTALS && !summary ? (
                <Box display="flex" justifyContent="top" alignItems="left" height="100vh">
                    <CircularProgress />
                </Box>
            ) : (

                <Grid container spacing={2.5}>
                    {summary &&
                        <>
                            <Grid item xs={12} sm={6} lg={2}>
                                {isReceiving.TOTALS ? (
                                    <Box display="flex" justifyContent="top" alignItems="left" height="100vh">
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <MemberCount member_count={summary.member_count} />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={2}>
                                {isReceiving.TOTALS ? (
                                    <Box display="flex" justifyContent="top" alignItems="left" height="100vh">
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <GroupCounterCard group_count={summary.group_count} />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={2}>
                                {isReceiving.TOTALS ? (
                                    <Box display="flex" justifyContent="top" alignItems="left" height="100vh">
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <LoanSumCard total_loan_amount={summary.total_loan_payable} />
                                )}
                            </Grid>

                            <Grid item xs={12} sm={6} lg={2}>
                                {isReceiving.TOTALS ? (
                                    <Box display="flex" justifyContent="top" alignItems="left" height="100vh">
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <SavingsSumCard total_savings={summary.total_savings_payable} />
                                )}
                            </Grid>

                            <Grid item xs={12} sm={6} lg={2}>
                                {isReceiving.TOTALS ? (
                                    <Box display="flex" justifyContent="top" alignItems="left" height="100vh">
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <Outstanding total_outstanding_amount={summary.total_outstanding_amount} />
                                )}
                            </Grid>

                            <Grid item xs={12} sm={6} lg={2}>
                                {isReceiving.TOTALS ? (
                                    <Box display="flex" justifyContent="top" alignItems="left" height="100vh">
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <InArrears total_default_amount={summary.total_default_amount} />
                                )}
                            </Grid>
                        </>
                    }
                    
                    {
                        /**<Grid item xs={12}>
                        <LoanSummary />
                    </Grid> <Grid item xs={12} lg={6}>
                            <PortfolioBalance />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <LoanOverview />
                        </Grid>
                        <Grid item xs={12}>
                            <MemberBreakdown />
                        </Grid>**/
                    }
                </Grid>
            )}
        </>
    );
};

export default MainDashboard;
