import {
    RECEIVED_MEMBERS,
    RECEIVING_MEMBERS,
    RECEIVED_ERROR_RESPONSE
} from '../actions/members';

const defaultState = {
    group_members: {},
    errorMessage: {},
    isReceiving: false,
};

export default function membersReducer(state = defaultState, action) {
    switch (action.type) {
        
        case RECEIVED_MEMBERS:
            return {
                ...state,
                group_members: action.payload,
                isReceiving: false,
            };
        case RECEIVING_MEMBERS:
            return {
                ...state,
                isReceiving: true
            };
        case RECEIVED_ERROR_RESPONSE:
            return {
                ...state,
                isReceiving: false,
                errorMessage: action.payload,
            };
        default:
            return state;
    }
}
