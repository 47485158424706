import React, { useEffect, useState, useCallback } from 'react';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { DialogActions, DialogContentText, DialogContent, Dialog, Paper, DialogTitle, Button, Stack } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import MuiReusableTable from '../../MuiReusableTable'
import { useSelector, useDispatch } from 'react-redux';
import ReportRequestItem from './ReportForm/ReportRequestItem';
import { config } from '../../../config/config'
import { getReports, updateReportStatus } from '../../../redux/actions/reports'
import NoDataFound from '../../NoDataFound'
import { format, parse, isBefore } from 'date-fns';
import useJumboAuth from "@jumbo/hooks/useJumboAuth";


const ReportRequestList = ({ reports_params, reports_requests, isReceivingReportRequests, searchCreteria }) => {

    const { authUser } = useJumboAuth();

    const dispatch = useDispatch();
    const [isReportModalOpen, setIsReportModalOpen] = React.useState(false);
    const [showRequestLink, setShowRequestLink] = React.useState(false);
    const [showAwaiting, setShowAwaiting] = React.useState(false);
    const [selectedReport, setSelectedReport] = React.useState({
        report_id: '',
        report_name: '',
        report_start_date: '',
        report_end_date: '',
        org_id: '',
        user_email: '',
        user_mobile: '',
        org_physical_address: '',
        organisation_name: '',
        licence_no: '',
        report_file: '',
        reportFileURL: '',
        report_file_name: '',
        client_name: '',
        created_by: '',
        licence_no: '',
        user_id: '',
        posible_actions: '',
        link_status: '',
        created_on: '',
        canEdit: false,
        canUpdateStatus: false,
        link_status: '',
        request_date: '',
    });


    const reports = reports_requests?.v_reports;
    const my_orgs = reports_params?.v_my_orgs;

    const handleAwaitingClose = () => {
        setShowAwaiting(false);
    }

    const handleRequestLinkClose = () => {
        setShowRequestLink(false);
    }

    const handleRequestLink = () => {
        let reportRequest = {
            user_email: authUser.user_email,
            user_id: authUser.u_user_id,
            role: authUser.role,
            report_id: selectedReport.report_id,
            action: 'GET_REPORT',
            created_by: authUser.u_user_id,
            location: authUser.locationData
        };
        dispatch(updateReportStatus(reportRequest, authUser));//fetch available reports
    }

    const handleReportCloseModal = () => {
        setIsReportModalOpen(false)
    }
    const handleViewReport = (row, index) => {
        //console.log(row);
        setSelectedReport({
            report_id: row.report_id,
            report_client_id: row.report_client_id,
            report_name: row.report_name,
            report_start_date: row.report_start_date,
            report_end_date: row.report_end_date,
            org_id: row.org_id,
            user_email: row.user_email,
            user_mobile: row.user_mobile,
            org_physical_address: row.org_physical_address,
            organisation_name: row.organisation_name,
            licence_no: row.licence_number,
            report_file: row.report_file ? config.base_url + row.report_file.replace('/srv/uploads', "/files") : '',
            reportFileURL: row.report_file ? config.base_url + row.report_file.replace('/srv/uploads', "/files") : '',
            report_file_name: row.report_file ? row.report_file.split('/').pop() : '',
            client_name: row.client_name,
            created_by: row.created_by,
            user_id: row.user_id,
            posible_actions: row.posible_actions,
            created_on: row.created_on,
            canEdit: row.canEdit == "false" ? false : true,
            canUpdateStatus: row.canUpdateStatus == "false" ? false : true,
            link_status: row.link_status,
            request_date: format(new Date(row.request_date), 'dd-MMM-yyyy'),
        });
        setIsReportModalOpen(true);
    }

    const handleAddReport = () => {
        setSelectedReport({
            report_id: '',
            report_client_id: '',
            report_name: '',
            report_start_date: '',
            report_end_date: '',
            org_id: '',
            user_email: '',
            user_mobile: '',
            org_physical_address: '',
            organisation_name: '',
            licence_no: '',
            report_file: '',
            reportFileURL: '',
            report_file_name: '',
            client_name: '',
            created_by: '',
            licence_no: '',
            user_id: '',
            posible_actions: '',
            link_status: '',
            created_on: '',
            canEdit: false,
            canUpdateStatus: false,
            request_date: ''
        });

        setIsReportModalOpen(true);
    }
    const columns = [
        { id: 'report_id', label: 'Report ID', minWidth: 50, type: 'string' },
        { id: 'report_name', label: 'Report Name', minWidth: 130, type: 'string' },
        { id: 'report_start_date', label: 'Start Date', minWidth: 120, type: 'date' },
        { id: 'report_end_date', label: 'End Date', minWidth: 120, type: 'date' },
        { id: 'client_name', label: 'Client Name', minWidth: 130, type: 'string' },
        { id: 'created_by', label: 'Prepared By', minWidth: 130, type: 'string' },
        { id: 'organisation_name', label: 'Organisation', minWidth: 130, type: 'string' },
        { id: 'licence_number', label: 'Licence Number', minWidth: 100, type: 'string' },
        { id: 'created_on', label: 'Created On', minWidth: 120, type: 'date' },
        { id: 'link_status', label: 'Report Status', minWidth: 70, type: 'string' },
    ];

    return (
        <JumboDemoCard title={''} wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>


            <Paper sx={{ width: '100%', overflow: 'hidden', m: 2 }}>

                {authUser && authUser.role === "report-mng" &&
                    <Button
                        variant="outlined"
                        size="large"
                        sx={{ mb: 3 }}
                        onClick={handleAddReport}
                        startIcon={<PersonAddAltIcon />}
                    >
                        New Report
                    </Button>
                }
                {reports ?

                    (<MuiReusableTable
                        rows={reports || []}
                        columns={columns}
                        table_size="small"
                        table_error={''}
                        isLoading={isReceivingReportRequests}
                        pagination={{
                            showPagination: true,
                            rowsPerPage: 6,
                        }}
                        table_actions={[
                            {
                                menuName: 'View Report',
                                method: (row, index) => handleViewReport(row, index)
                            },
                        ]}
                    />

                    ) :

                    <NoDataFound />
                }

            </Paper>


            <Dialog
                open={showRequestLink}
                onClose={handleRequestLinkClose}
                aria-labelledby="request-link-dialog-title"
                aria-describedby="request-link-dialog-description"
                PaperProps={{
                    style: {
                        height: 'auto',
                        width: '30%',
                        maxWidth: 'none'
                    }
                }}
            >
                <DialogTitle id="request-link-dialog-title">Request Report Linking</DialogTitle>
                <DialogContent>
                    <DialogContentText id="request-link-dialog-description">
                        Do you want to request access to this report?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRequestLinkClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleRequestLink} color="primary" autoFocus>
                        Send Request
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showAwaiting}
                onClose={handleAwaitingClose}
                aria-labelledby="request-link-dialog-title"
                aria-describedby="request-link-dialog-description"
                PaperProps={{
                    style: {
                        height: 'auto',
                        width: '30%',
                        maxWidth: 'none'
                    }
                }}
            >
                <DialogTitle id="request-link-dialog-title">System Alert</DialogTitle>
                <DialogContent>
                    <DialogContentText id="request-link-dialog-description">
                        We are sorry for keeping you waiting. Your request will be approved soon!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAwaitingClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isReportModalOpen}
                onClose={handleReportCloseModal}
                fullWidth
                maxWidth="xl"
                PaperProps={{
                    style: {
                        height: 'auto',
                        width: '70%',
                        maxWidth: 'none'
                    }
                }}
            >
                <DialogTitle>Report Management</DialogTitle>
                <DialogContent>
                    <ReportRequestItem
                        setIsReportModalOpen={setIsReportModalOpen}
                        reports_params={reports_params}
                        searchCreteria={searchCreteria}
                        initialValues={selectedReport} />
                </DialogContent>
            </Dialog>

        </JumboDemoCard>
    );
};

export default ReportRequestList;
