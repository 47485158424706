import React, { useEffect, useState } from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import LoanProductSummary from "./components/LoanProductSummary";
import LoanCategorySummary from "./components/LoanCategorySummary";
import { Grid, CircularProgress, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Div from "@jumbo/shared/Div";
import { format, parse } from 'date-fns';
import { getDashBoardData } from '../../../redux/actions/dashboard'
import { useSelector, useDispatch } from 'react-redux';

const userString = localStorage.getItem('user');
const authUser = userString ? JSON.parse(userString) : undefined;

function getMonths(noOfMonths) {
    const firstDays = [];
    const currentDate = new Date();

    for (let i = 0; i < noOfMonths; i++) {
        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth());
        firstDays.push(format(firstDay, 'yyyy-MM-dd')); // Format to 'YYYY-MM-DD'
        currentDate.setMonth(currentDate.getMonth() - 1);
    }

    return firstDays.reverse(); // To have the months in chronological order
}

function addMissingMonths(transformedData) {
    const lastMonths = getMonths(15);

    return transformedData.map(product => {
        const existingMonths = product.dist.map(item => item.month);


        lastMonths.forEach(month => {
            if (!existingMonths.includes(month)) {
                product.dist.push({
                    month: month,
                    principal: 0
                });
            }
        });

        // Sort the dist array by month to keep them in chronological order
        product.dist.sort((a, b) => new Date(a.month) - new Date(b.month));

        return product;
    });
}

const LoanSummary = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (authUser && !isReceiving) {

            dispatch(getDashBoardData({
                action: "MONTHLY"
            }, authUser));
        }

    }, [dispatch, authUser]);

    const dashboardData = useSelector(state => state.dashboard.data.MONTHLY);
    const isReceiving = useSelector(state => state.dashboard.isReceiving.MONTHLY)

    ////console.log(authUser, isReceiving);

    let summary = {};

    if (dashboardData) {
        summary = dashboardData.v_summary;
    }

    let loan_summary_per_product = [];
    let loan_summary_per_product_per_month = [];
    let totalLoansPerProduct = [];
    let transformedData = [];
    let data = [];
    if (summary) {
        loan_summary_per_product = summary.loan_summary_per_product;
        loan_summary_per_product_per_month = summary.loan_summary_per_product_per_month;

        ////console.log(loan_summary_per_product_per_month)
        transformedData = loan_summary_per_product_per_month.reduce((acc, current) => {
            // Find if the productName already exists in the accumulator
            let existingProduct = acc.find(item => item.product === current.productName);

            if (existingProduct) {
                // If it exists, push the month and principal to the dist array
                existingProduct.dist.push({
                    month: current.month,
                    principal: current.principal
                });
            } else {
                // If it doesn't exist, create a new product entry
                acc.push({
                    product: current.productName,
                    dist: [{
                        month: current.month,
                        principal: current.principal
                    }]
                });
            }

            return acc;
        }, []);

        data = transformedData ? addMissingMonths(transformedData) : [];
    }


    const { t } = useTranslation();
    return (
        <JumboCardQuick
            title={t("widgets.title.loanProductSummary")}
            wrapperSx={{ pt: 1 }}
        >
            {isReceiving.MONTHLY ? (
                <Box display="flex" justifyContent="top" alignItems="left" height="100vh">
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={3.75}>
                    <Grid item xs={12} sm={12} lg={3} sx={{ order: { lg: 3 } }}>
                        {loan_summary_per_product &&
                            <LoanCategorySummary loan_product_summary={{
                                loan_summary_per_product: loan_summary_per_product,
                                total_loans_per_product: totalLoansPerProduct
                            }} />
                        }
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Div sx={{
                            p: theme => theme.spacing(3, 2, 3, 0)
                        }}>
                            {data &&
                                <LoanProductSummary loan_summary_per_product_per_month={data} />
                            }
                        </Div>
                    </Grid>
                </Grid>
            )}
        </JumboCardQuick>
    );
};

export default LoanSummary;
