import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';

const MemberCount = ({ member_count }) => {
    return (
        <JumboCardQuick bgColor={'#6f42c1'}>
            <Div sx={{ display: "flex", alignItems: 'center' }}>
                {//<img alt={"Members Icon"}  style={{ width: '46px', height: '46px' }} src={getAssetPath(`${ASSET_IMAGES}/dashboard/people_icon.svg`, "46x46")} />
                }
                <Diversity2OutlinedIcon sx={{ fontSize: 46, color: 'white' }} />
                <Div sx={{ ml: 2, flex: 1 }}>
                    <Typography color={'common.white'} variant={"h2"} mb={.5}>{new Intl.NumberFormat().format(member_count)}</Typography>
                    
                </Div>
                
            </Div>
            <Typography color={'common.white'} variant={"h5"} mb={0}>Members</Typography>
        </JumboCardQuick>
    );
};

export default MemberCount;
