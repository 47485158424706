// validationSchemas.js
import * as yup from 'yup';
import { subYears, subDays } from 'date-fns';
import { format, parse } from 'date-fns';

export const personalDetailsSchema = yup.object().shape({
    idtype: yup.string().required('ID Type is required'),
    gender: yup.string().required('Gender is required'),
    id_idFile: yup
        .mixed()
        .required('ID File is required')
        .test('fileSize', 'File Size is too large', value => {
            return value && value.size <= 2000000; // 2MB size limit
        })
        .test('fileType', 'Unsupported File Format', value => {
            return value && ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
        }),
    idnumber: yup.string().required('ID Number is required'),
    lastname: yup.string().required('Last Name is required'),
    firstname: yup.string().required('First Name is required'),
    dateofbirth: yup.date()
        .required('Date of Birth is required')
        .nullable()
        .max(subYears(new Date(), 18), 'Must be at least 18 years old')
});

export const nextOfKinCountSchema = yup.array().min(2, 'You must add at least 2 next of kin.');


export const organisationValidation = yup.object().shape({
    organisation_name: yup.string()
        .required('Organisation Name is required')
        .min(3, 'Organisation Name must be at least 3 characters long')
        .max(100, 'Organisation Name cannot exceed 100 characters'),

    organisation_pin: yup.string()
        .required('KRA PIN is required')
        .matches(/^[A-Za-z]\d{9}[A-Za-z]$/, 'KRA PIN must start and end with a letter, contain 11 characters total, and the middle 9 characters must be numbers'),

    org_emailaddress: yup.string()
        .required('Organisation Email Address is required')
        .email('Enter a valid email address'),

    org_mobilenumber: yup.string()
        .required('Organisation Mobile Number is required')
        .matches(/^[0-9]{10}$/, 'Mobile Number must be 10 digits'),

    org_description: yup.string()
        .required('Organisation Description is required')
        .min(10, 'Description must be at least 10 characters long')
        .max(500, 'Description cannot exceed 500 characters'),

    org_physical_address: yup.string()
        .required('Organisation Physical Address is required')
        .min(5, 'Physical Address must be at least 5 characters long')
        .max(255, 'Physical Address cannot exceed 255 characters'),
});


export const nokValidationSchema = yup.object().shape({
    nok_name: yup.string().required('Name is required'),
    nok_gender: yup.string().required('Gender is required'),
    nok_dateofbirth: yup.date().required('Date of Birth is required').nullable().when('isvalidator', {
        is: true,
        then: yup.date().max(subYears(new Date(), 18), 'Must be at least 18 years old'),
        otherwise: yup.date().max(subDays(new Date(), 1), 'Must be at least 1 day old')
    }),
    relationship: yup.string().required('Relationship is required'),
    validator_idtype: yup.string().when('isvalidator', {
        is: true,
        then: yup.string().required('ID Type is required'),
        otherwise: yup.string()
    }),
    validator_idnumber: yup.string().when('isvalidator', {
        is: true,
        then: yup.string().required('ID Number is required'),
        otherwise: yup.string()
    }),
    validator_mobile: yup.string().when('isvalidator', {
        is: true,
        then: yup.string().required('Mobile is required'),
        otherwise: yup.string()
    }),
    validator_address: yup.string().when('isvalidator', {
        is: true,
        then: yup.string().required('Address is required'),
        otherwise: yup.string()
    }),
    isvalidator: yup.boolean().required('Validator status is required')
});

export const contactsAndBankingSchema = yup.object().shape({
    bank: yup.string().required('Bank is required'),
    branch: yup.string().required('Branch is required'),
    account: yup.string().required('Account number is required'),
    emailaddress: yup.string().email('Invalid email address').required('Email address is required'),
    is_chair: yup.boolean(),
    is_secretary: yup.boolean(),
    is_treasurer: yup.boolean(),
    mobilenumber: yup.number().required('Mobile number is required'),
    monthlyincome: yup.number().required('Monthly income is required').positive('Monthly income must be a positive number'),
    member_physical_address: yup.string().required('Member physical address is required')
});

export const groupSchema = yup.object().shape({
    email: yup.string().email('Invalid email address').required('Email address is required'),
    mobile: yup.number().required('Mobile number is required'),
    status: yup.string().required('Group status is required'),
    group_name: yup.string().required('Group name is required'),
    group_type: yup.string().required('Group type is required'),
    institution: yup.number().required('Institution is required'),
    institution_branch: yup.number().required('Branch address is required'),
    account: yup.string().required('Account number is required'),
    postal_address: yup.string().required('Postal address is required'),
    physical_address: yup.string().required('Physical address is required'),
    group_description: yup.string().required('Group description is required'),
    registrationCertNo: yup
        .mixed()
        .when('$isNewGroup', {
            is: true,
            then: yup.mixed().required('Registration File is required'),
            otherwise: yup.mixed(),
        })
        .test('fileSize', 'Registration File Size is too large', value => {
            return value && value.size <= 2000000; // 2MB size limit
        })
        .test('fileType', 'Unsupported File Format', value => {
            return value && ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
        }),
});

export const reportValidationSchema = yup.object().shape({
    report_name: yup.string()
        .required('Report Name is required')
        .min(3, 'Report Name must be at least 3 characters long')
        .max(100, 'Report Name cannot exceed 100 characters'),

    report_start_date: yup.date()
        .required('Report Start Date is required')
        .nullable()
        .typeError('Report Start Date must be a valid date'),

    report_end_date: yup.date()
        .required('Report End Date is required')
        .nullable()
        .typeError('Report End Date must be a valid date'),

    org_number: yup.string()
        .required('Organization ID is required')
        .matches(/^\d+$/, 'Organization ID must be numeric'),

    licence_number: yup.string()
        .required('Licence Number is required')
        .matches(/^\d{8}$/, 'Licence Number must be exactly 8 digits'),

    report_file: yup.mixed()
        .required('A report file is required')
        .test('fileSize', 'File too large', value => value && value.size <= 5 * 1024 * 1024) // Limit file size to 5MB
        .test('fileType', 'Unsupported file format', value => {
            return value && ['application/pdf'].includes(value.type);
        }),
});

