import React, { useState, useEffect } from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { LinearProgress, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import styled from "@mui/material/styles/styled";
import { useTranslation } from "react-i18next";

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    flex: 1
}));

const MemberAgeSplit = ({ members }) => {

    //////console.log(members);

    const { t } = useTranslation();

    const [totalAgeCount, setTotalAgeCount] = useState(0);
    const [totalGenderCount, setTotalGenderCount] = useState(0);

    useEffect(() => {
        const sumAgeCount = members.age_range.reduce((acc, item) => acc + item.age_count, 0);
        const sumGenderCount = members.gender_split.reduce((acc, item) => acc + item.gender_count, 0);
        setTotalAgeCount(sumAgeCount);
        setTotalGenderCount(sumGenderCount);
    }, []);

    const colorMap = [
        'primary',
        'secondary',
        'success',
        'info',
        'warning',
        'danger',
        'blue',
        'indigo',
        'purple',
        'pink',
        'red',
        'orange',
        'green',
        'teal',
        'cyan',
        'gray',
        'white',
        'brown',
    ];


    return (
        <React.Fragment>
            {members &&
                <>
                    <Typography variant={'h5'} style={{ fontWeight: 'bold' }} color={'text.primary'}>{"Members by Age"}</Typography>
                    {
                        members.age_range.map((item, index) => {

                            let value = parseFloat(((item.age_count / totalAgeCount) * 100).toFixed(2));
                            return (
                                <React.Fragment key={index}>
                                    <Typography variant={'body1'} color={'text.secondary'}>{item.from + " - " + item.to + " years"}</Typography>
                                    <Div
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: 2
                                        }}
                                    >
                                        <StyledLinearProgress variant={"determinate"} value={value} color={colorMap[index]} />
                                        <Typography
                                            variant={'body1'}
                                            color={'text.secondary'}
                                            ml={1}
                                        >
                                            {`${value}%`}
                                        </Typography>
                                    </Div>
                                </React.Fragment>
                            )
                        }
                        )}
                    <Typography variant={'h5'} style={{ fontWeight: 'bold' }} color={'text.primary'}>{"Gender Distribution"}</Typography>
                    {
                        members.gender_split.map((item, index) => {
                            let value = parseFloat(((item.gender_count / totalGenderCount) * 100).toFixed(2));
                            return (
                                <React.Fragment key={index}>
                                    <Typography variant={'body1'} color={'text.secondary'}>{item.gender}</Typography>
                                    <Div
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: 2
                                        }}
                                    >
                                        <StyledLinearProgress variant={"determinate"} value={value} color={colorMap[index]} />
                                        <Typography
                                            variant={'body1'}
                                            color={'text.secondary'}
                                            ml={1}
                                        >
                                            {`${value}%`}
                                        </Typography>
                                    </Div>
                                </React.Fragment>
                            )
                        })
                    }

                </>
            }
        </React.Fragment>
    );
};

export default MemberAgeSplit;
