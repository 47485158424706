import React from 'react';
import {useNavigate} from "react-router-dom";
import {routesForAuthenticatedOnly, routesForNotAuthenticatedOnly} from "../../../app/routes";
import useRoutePathMatch from "@jumbo/hooks/useRoutePathMatch";
import {removeToken, storeToken} from "./authHelpers";
import {config} from "../../../app/config/main";
import {AuthContext} from "@jumbo/components/JumboAuthProvider/JumboAuthContext";


const storedToken = localStorage.getItem("token");
let firstTimePageLoad = true;

const init = () => {
    const authUser= JSON.parse(localStorage.getItem("authUser")); // Retrieve user data from localStorage
    const authToken = localStorage.getItem("token");

    if (authToken) {
        storeToken(authToken); // also sets axios header
    }

    return {
        authToken: authToken ?? null,
        authUser: authUser ?? null,
        isLoading: false,
        fallbackPath: config.authSetting.fallbackPath,
    };
};

const authReducer = (state, action) => {
    switch (action.type) {
        case "set-auth-data":
            return {
                ...state,
                ...action.payload,
            };

        case "start-loading":
            return {
                ...state,
                isLoading: true,
            };

        case "stop-loading":
            return {
                ...state,
                isLoading: false,
            };
    }
};
const JumboAuthProvider = ({children, ...restProps}) => {
    const [authOptions, setAuthOptions] = React.useReducer(authReducer, restProps, init);
    const [logout, setLogout] = React.useState(false);
    const navigate = useNavigate();
    const isAuthenticatedRouteOnly = useRoutePathMatch(routesForAuthenticatedOnly);
    const isNotAuthenticatedRouteOnly = useRoutePathMatch(routesForNotAuthenticatedOnly);

    React.useEffect(() => {
        if (logout) {
            // Clear authentication data
            localStorage.removeItem("authUser");
            localStorage.removeItem("token");
            removeToken();
            setAuthOptions({
                type: "set-auth-data",
                payload: {authToken: null, authUser: null, isLoading: false}
            });
            navigate(config.authSetting.fallbackPath);
            setLogout(false);
        }
    }, [logout, navigate]);


   


    const setAuthToken = React.useCallback(async (token) => {
        setAuthOptions({type: "start-loading"});
        if (!token) {
            setLogout(true);
            return;
        }

        storeToken(token);
        try {
            const authUser = await config?.authSetting?.getAuthUserService();
            if (authUser) {
                setAuthOptions({
                    type: "set-auth-data",
                    payload: {authToken: token, authUser: authUser, isLoading: false}
                });
                return;
            }
            setLogout(true);
        } catch (error) {
            setLogout(true);
            console.error(error);
        }
    }, []);

    const setAuthData = React.useCallback((authUser) => {

        localStorage.setItem("authUser", JSON.stringify(authUser));
        setAuthOptions({type: "set-auth-data", payload: authUser});
    }, []);

    const setRedirectPath = React.useCallback((redirectPath) => {
        setAuthOptions({type: "set-redirect-path", payload: {redirectPath}});
    }, []);

    const contextValue = React.useMemo(() => ({
        ...authOptions,
        setAuthData,
        setRedirectPath,
        setAuthToken,
        setAuthOptions,
        logout: () => setLogout(true) // Add logout function to context
    }), [authOptions]);

    React.useEffect(() => {
        if (!authOptions.authToken) {
            if (isAuthenticatedRouteOnly) {
                navigate(authOptions?.fallbackPath);
            }
        } else if (!authOptions.authUser) {
            setAuthToken(authOptions.authToken);
        } else if (isNotAuthenticatedRouteOnly) {
            if (!firstTimePageLoad)
                navigate(config.authSetting.redirectNotAuthenticatedPath ?? "/");
            else
                firstTimePageLoad = false;
        }
    }, [authOptions.authUser]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default JumboAuthProvider;