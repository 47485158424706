import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import * as Yup from 'yup';
import { format, parse, isBefore } from 'date-fns';
import { reportValidationSchema } from '../../../FormValidation';
import { updateReport, updateReportStatus } from "../../../../redux/actions/reports";
import { config } from '../../../../config/config';
import Invoice from './Invoice';
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {
    TextField,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Typography,
    Modal,
    InputAdornment,
    FormHelperText,
} from '@mui/material';

function toPascalCase(str) {
    return str
        .replace(/[_-]+/g, ' ')           // Replace underscores and hyphens with spaces
        .toLowerCase()                    // Convert the entire string to lowercase
        .split(' ')                       // Split the string into words
        .map(word =>
            word.charAt(0).toUpperCase() + word.slice(1) // Capitalize the first letter of each word
        )
        .join('');                        // Join the words back together without spaces
}

const ReportRequestItem = ({ reports_params, setIsReportModalOpen, initialValues, searchCreteria }) => {

    const { authUser } = useJumboAuth();
    const dispatch = useDispatch();
    const reportFileInputRef = useRef(null);
    const [values, setValues] = useState(initialValues || {
        canEdit: true,
        report_client_id: '',
        canUpdateStatus: false,
        report_id: null,
        report_name: '',
        report_start_date: '',
        report_end_date: '',
        org_id: '',
        user_mobile: '',
        org_physical_address: '',
        organisation_name: '',
        licence_no: '',
        report_file: '',
        status: 'PENDING',
        report_file_name: '',
        posible_actions: '',
        reportFileURL: '',
        request_date: ''
    });

    const [errors, setErrors] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
    const [ivoiceParameters, setIvoiceParameters] = useState({});
    const { v_my_orgs, v_report_cost } = reports_params;


    const handleSaveReport = () => {

        const request = {
            report_id: values.report_id,
            report_client_id: values.report_client_id,
            org_id: values.org_id,
            user_mobile: values.user_mobile,
            org_physical_address: values.org_physical_address,
            organisation_name: values.organisation_name,
            user_id: authUser.u_user_id,
            client_name: searchCreteria.client_name,
            report_name: searchCreteria.report_name,
            report_start_date: searchCreteria.date_from,//am reusing this key to search
            report_end_date: searchCreteria.date_to,//am reusing this key to search
            licence_number: authUser.licence_no,
            created_by: authUser.user_email,
            location: authUser.locationData,
            role: authUser.role,
            invoiceData: ivoiceParameters,
            action: values.status + "_REQUEST"
        };

        //console.log(request);
        dispatch(updateReportStatus(request, authUser));
        setIsReportModalOpen(false);
    }


    const handleVerify = () => {
        ////console.log(values.org_number);
    }

    const handleChangeLicence = (event) => {

        const { name, value } = event.target;
        setValues(prev => ({ ...prev, [name]: value }));
        if (value.length > 8 && value !== "123000321") {
            setErrors(prev => ({ ...prev, licence_number: "Enter valid licence" }));
        } else {
            validateField(name, value);
        }

    };


    const handleChangeOrgID = (event) => {
        const { name, value } = event.target;

        ////console.log(name, value)
        setValues(prev => ({ ...prev, [name]: value }));
        // Validate field value on change
        validateField(name, value);
    };

    const handlePayInvoice = () => {
        setIvoiceParameters({
            ...ivoiceParameters,
            paymentApproved: true,
        });

        handleChange({
            target: {
                name: 'status',
                value: 'APPROVE',
            },
        });

        setOpenInvoiceModal(false);
    }
    const handleCloseInvoiceModal = () => {
        setOpenInvoiceModal(false);
    }

    const handleChangeStatus = (event) => {
        const { name, value } = event.target;
        //console.log(v_report_cost);
        const cost_parameter = value == "APPROVE" ? 'client-access' : '';
        //console.log(v_report_cost);
        let costData = v_report_cost.find((cost) => {
            return cost.type === cost_parameter.toLowerCase();
        })

        if (costData) {

            //console.log(costData.price);
            const invoiceData = {
                invoiceNumber: 'INV-' + values.report_id + values.org_id,
                date: format(new Date(), 'dd-MMM-yyyy'),
                dueDate: format(new Date(), 'dd-MMM-yyyy'),
                customer: {
                    name: values.created_by + ' ' + values.licence_no,
                    email: values.user_email,
                    mobile: values.user_mobile,
                },
                company: {
                    name: values.organisation_name,
                    address: values.org_physical_address,
                },
                items: [
                    { description: values.report_name + ' / ' + values.licence_no + "\nPeriod: " + values.report_start_date + ' to ' + values.report_end_date, quantity: 1, price: costData.price },
                ],
                totalAmount: costData.price,
            }
            setIvoiceParameters(invoiceData)
            setOpenInvoiceModal(true);
        } else {
            handleChange(event)
        }

    };


    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues(prev => ({ ...prev, [name]: value }));

        // Validate field value on change
        validateField(name, value);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        let reportFileURL = URL.createObjectURL(file)
        //console.log(file);
        if (file) {
            setValues(prev => ({ ...prev, report_file: file, report_file_name: file.name, reportFileURL: reportFileURL }));
            setErrors(prev => ({ ...prev, report_file: undefined }));
        }
    };

    const handleTextFieldClick = (event) => {
        // Ignore click events from buttons inside the TextField
        if (event.target.closest('button')) return;

        reportFileInputRef.current.click();
    };

    const handleStartDateChange = (date) => {
        let formatedDate = format(date, 'yyyy-MM-dd');
        handleChange({
            target: {
                name: 'report_start_date',
                value: formatedDate,
            },
        });
    };

    const handleEndDateChange = (date) => {
        let formatedDate = format(date, 'yyyy-MM-dd');

        if (values.report_start_date !== '') {
            ////console.log(date, new Date(values.report_start_date))
            if (isBefore(date, new Date(values.report_start_date), { unit: 'day' })) {
                setErrors(prev => ({ ...prev, 'report_end_date': "End date can not be before start date" }));
            } else {
                handleChange({
                    target: {
                        name: 'report_end_date',
                        value: formatedDate,
                    },
                });
            }

        } else {
            setErrors(prev => ({ ...prev, 'report_end_date': "You must first select the start date" }));
        }

    };

    const validateField = async (name, value) => {
        try {
            const schema = Yup.object().shape({
                [name]: reportValidationSchema.fields[name],
            });
            await schema.validate({ [name]: value });
            setErrors(prev => ({ ...prev, [name]: undefined }));
        } catch (error) {
            setErrors(prev => ({ ...prev, [name]: error.message }));
        }
    };

    const handleFocus = (event) => {
        const { name } = event.target;
        setErrors(prev => ({ ...prev, [name]: undefined }));
    };

    const handleViewFile = () => {

        if (values.report_file) {
            //console.log(values.report_file)
            setOpenModal(true); // Open modal
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false); // Close modal
    };

    const handleClearFile = () => {
        setValues(prev => ({
            ...prev,
            report_file: null,
            report_file_name: null
        }));
    };


    return (

        <Box sx={{ position: 'relative', p: 3, backgroundColor: 'background.paper', borderRadius: 2 }}>
            <Grid container spacing={2}>

                <Grid item xs={12} sm={5}>
                    <TextField
                        name="report_name"
                        fullWidth
                        margin="normal"
                        label="Report Name"
                        disabled={true}
                        value={values.report_name}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <TextField
                        name="client_name"
                        fullWidth
                        margin="normal"
                        label="Client Name"
                        disabled={true}
                        value={values.client_name}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextField
                        name="request_date"
                        fullWidth
                        margin="normal"
                        label="Requested On"
                        disabled={true}
                        value={values.request_date}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="organisation_name"
                        fullWidth
                        margin="normal"
                        disabled={true}
                        label="Org Name"
                        value={values.organisation_name}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="created_by"
                        fullWidth
                        margin="normal"
                        disabled={true}
                        label="Prepared By"
                        value={values.created_by}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="licence_number"
                        fullWidth
                        margin="normal"
                        disabled={true}
                        label="Licence No."
                        value={values.licence_no}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>

                    <TextField
                        fullWidth
                        margin="normal"
                        label="Report File"
                        disabled={true}
                        value={values.report_file_name}
                        InputProps={{
                            readOnly: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleViewFile}
                                        style={{ marginRight: '8px' }}
                                    >
                                        View
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                        helperText={errors.id_idFile}
                        error={Boolean(errors.id_idFile)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <DatePicker
                                    label="Start Date"
                                    inputFormat="yyyy-MM-dd"
                                    disabled={true}
                                    value={values.report_start_date}
                                    onChange={handleStartDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            margin="normal"
                                            error={Boolean(errors.report_start_date)}
                                            helperText={errors.report_start_date}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DatePicker
                                    label="End Date"
                                    inputFormat="yyyy-MM-dd"
                                    disabled={true}
                                    value={values.report_end_date}
                                    onChange={handleEndDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            margin="normal"
                                            error={Boolean(errors.report_end_date)}
                                            helperText={errors.report_end_date}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                </Grid>
                {
                    values.canUpdateStatus && values.posible_actions &&
                    <Grid item xs={12} sm={3}>
                        <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel>Change Status</InputLabel>
                            <Select
                                name="status"
                                value={values.status}
                                onChange={handleChangeStatus}
                                onFocus={handleFocus}
                                label="Change Status"
                                error={Boolean(errors.status)}
                            >
                                {values.posible_actions.map((status) => (
                                    <MenuItem key={status.value} value={status.value}>
                                        {status.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.status}</FormHelperText>
                        </FormControl>
                    </Grid>
                }
            </Grid>

            <Box display="flex" justifyContent="space-between" mt={2}>
                <Box display="flex" justifyContent="flex-end" width="100%">
                    {(values.canEdit || (values.status && values.status !== 'PENDING')) &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveReport} // Handle Save button click
                        >
                            {values.status && values.status !== 'PENDING' ? toPascalCase(values.status) : 'Save Report'}
                        </Button>
                    }
                </Box>

            </Box>

            {/* Modal for Viewing Report File */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="file-viewer-modal"
                aria-describedby="file-viewer-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >


                    <Typography variant="h6" component="h2" gutterBottom>
                        {values.id_file_name}
                    </Typography>
                    {values.report_file && (
                        <iframe
                            src={values.reportFileURL}
                            width="100%"
                            height="750px"
                            title="File Preview"
                            style={{ border: 'none' }}
                        />
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCloseModal}
                        style={{ marginTop: '16px' }}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>

            <Modal
                open={openInvoiceModal}
                onClose={handleCloseInvoiceModal}
                aria-labelledby="file-viewer-modal"
                aria-describedby="file-viewer-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" component="h2" gutterBottom>
                        {'Invoice'}
                    </Typography>
                    <Invoice invoice={ivoiceParameters} />
                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCloseInvoiceModal}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePayInvoice}
                        >
                            Pay Invoice
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default ReportRequestItem;
