import React, { useState, useEffect } from 'react';
import { LinearProgress, Typography, Box } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    flex: 1
}));

const LoanCategorySummary = ({ loan_product_summary }) => {

    const { t } = useTranslation();
    const { loan_summary_per_product, total_loans_per_product } = loan_product_summary;

    const colorMap = [
        '#D32F2F', '#C2185B', '#7B1FA2', '#512DA8', '#303F9F',
        '#1976D2', '#0288D1', '#0097A7', '#00796B', '#388E3C',
        '#689F38', '#FBC02D', '#FFA000', '#F57C00', '#E64A19',
        '#5D4037', '#616161', '#455A64', '#9E9E9E', '#795548',
        '#9C27B0', '#3F51B5', '#03A9F4', '#00BCD4', '#009688',
        '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107',
        '#FF9800', '#FF5722', '#607D8B', '#000000', '#FFFFFF',
        '#8E24AA', '#3949AB', '#00ACC1', '#00897B', '#7CB342',
        '#FDD835', '#FB8C00', '#F4511E', '#6D4C41', '#757575',
        '#546E7A',
    ];

    const [totalLoan, setTotalLoan] = useState(0);
    const [totalLoanPerProduct, setTotalLoanPerProduct] = useState(0);

    useEffect(() => {
        const sum = loan_summary_per_product ? loan_summary_per_product.reduce((acc, item) => acc + item.sum_loan, 0) : 0;
        setTotalLoan(sum);
    }, [loan_summary_per_product]);

    useEffect(() => {
        const sum = total_loans_per_product ? total_loans_per_product.reduce((acc, item) => acc + item.sum_loans, 0) : 0;
        setTotalLoanPerProduct(sum);
    }, [total_loans_per_product]);

    return (
        <React.Fragment>
            <Typography variant={"h5"}>{t("widgets.subheader.loancategory")}</Typography>

            {loan_summary_per_product.map((item, key) => {
                let value = parseFloat(((item.sum_loan / totalLoan) * 100).toFixed(2));
                return (
                    <React.Fragment key={key}>
                        <Typography variant={'body1'} color={'text.secondary'}>{item.tag}</Typography>
                        <Div
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: .5
                            }}
                        >
                            <StyledLinearProgress
                                variant={"determinate"}
                                value={value}
                                sx={{ bgcolor: "#98AFC7" }}
                            />
                            <Typography
                                variant={'body1'}
                                color={'text.primary'}
                                ml={1}
                            >{`${value}%`}</Typography>
                        </Div>
                    </React.Fragment>
                )
            }
            )}
            <Box mt={2} />
            <Typography variant={"h5"}>{t("widgets.subheader.loanproductsperf")}</Typography>
            {total_loans_per_product.map((product, key) => {

                let value = parseFloat(((product.sum_loans / totalLoanPerProduct) * 100).toFixed(2));
                return (
                    <React.Fragment key={key}>
                        <Typography variant={'body2'} color={'text.primary'}>{product.product}</Typography>
                        <Div
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: .5
                            }}
                        >
                            <StyledLinearProgress
                                variant={"determinate"}
                                value={value}
                                sx={{ bgcolor: "#98AFC7" }}
                            />
                            <Typography
                                variant={'body1'}
                                color={'text.primary'}
                                ml={1}
                            >{`${value}%`}</Typography>
                        </Div>
                    </React.Fragment>
                )
            })}
        </React.Fragment>
    );
};

export default LoanCategorySummary;
