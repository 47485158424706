import React, { useState, useEffect } from 'react';
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Box,
    FormHelperText,
    Button
} from '@mui/material';
import * as Yup from 'yup';
import { organisationValidation } from '../../../../FormValidation';

export const Organisation = ({
    verifyUserOrg,
    activeStep,
    handleNext,
    handleBack,
    noOfSteps,
    updateContactsAndBanking,
    isUpdating
}) => {


    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});



    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues(prev => ({ ...prev, [name]: value }));

        // Validate field value on change
        validateField(name, value);
    };

    const handleSaveMemberClick = () => {
        updateContactsAndBanking(values);
    }

    const validateField = async (name, value) => {
        try {
            const schema = Yup.object().shape({
                [name]: organisationValidation.fields[name]
            });
            await schema.validate({ [name]: value });
            setErrors(prev => ({ ...prev, [name]: undefined }));
        } catch (error) {
            setErrors(prev => ({ ...prev, [name]: error.message }));
        }
    };

    const handleNextClick = async (event) => {
        try {
            const label = event.target.innerText.toLowerCase();
            await organisationValidation.validate(values, { abortEarly: false });
            handleNext({ organisationDetails: values }, label); // Proceed to the next step if there are no validation errors
        } catch (validationErrors) {
            ////console.log(validationErrors)
            const errorMessages = validationErrors.inner.reduce((acc, error) => {
                acc[error.path] = error.message;
                return acc;
            }, {});
            setErrors(errorMessages);
        }
    };

    const handleBackClick = async () => {
        handleBack({ contactsAndBanking: values });
    };

    return (
        <Box sx={{ position: 'relative', p: 3, backgroundColor: 'background.paper', borderRadius: 2 }}>
            <Grid container spacing={2}>


                <Grid item xs={12} sm={9}>
                    <TextField
                        name="organisation_name"
                        value={values.organisation_name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        label="Organisation Name"
                        helperText={errors.organisation_name}
                        error={Boolean(errors.organisation_name)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        name="organisation_pin"
                        value={values.organisation_pin}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        label="KRA PIN"
                        helperText={errors.organisation_pin}
                        error={Boolean(errors.organisation_pin)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="org_emailaddress"
                        value={values.org_emailaddress}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        label="Organisation Email Address"
                        helperText={errors.org_emailaddress}
                        error={Boolean(errors.org_emailaddress)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="org_mobilenumber"
                        value={values.org_mobilenumber}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        label="Organisation Mobile Number"
                        helperText={errors.org_mobilenumber}
                        error={Boolean(errors.org_mobilenumber)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="org_description"
                        value={values.org_description}
                        onChange={handleChange}
                        fullWidth
                        minRows={2}
                        multiline
                        margin="normal"
                        label="Organisation Description"
                        helperText={errors.org_description}
                        error={Boolean(errors.org_description)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="org_physical_address"
                        value={values.org_physical_address}
                        onChange={handleChange}
                        fullWidth
                        minRows={2}
                        multiline
                        margin="normal"
                        label="Organisation Physical Address"
                        helperText={errors.org_physical_address}
                        error={Boolean(errors.org_physical_address)}
                    />
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="space-between" mt={2}>
                {isUpdating ? (
                    <Box display="flex" justifyContent="flex-end" width="100%">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleSaveMemberClick} // Handle Save button click
                        >
                            Save Organisation
                        </Button>
                    </Box>
                ) : (
                    <>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleBackClick}
                            disabled={activeStep === 0}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNextClick} // Handle Next button click
                        >
                            {activeStep === noOfSteps - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </>
                )}
            </Box>
        </Box>
    );
};
