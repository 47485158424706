import {
    RECEIVED_CUSTOMERS,
    RECEIVING_CUSTOMERS,
    RECEIVED_CUSTOMER_PARAMS,
    RECEIVING_CUSTOMER_PARAMS,
    RECEIVED_ERROR_RESPONSE
} from '../actions/customers';

const defaultState = {
    customer_data: {},
    customer_params: {},
    errorMessage: {},
    isReceiving: false,
};

export default function membersReducer(state = defaultState, action) {
    switch (action.type) {

        case RECEIVED_CUSTOMERS:
            return {
                ...state,
                customer_data: action.payload,
                isReceiving: false,
            };
        case RECEIVING_CUSTOMERS:
            return {
                ...state,
                isReceiving: true
            };
        case RECEIVED_CUSTOMER_PARAMS:
            return {
                ...state,
                customer_params: action.payload,
                isReceiving: false,
            };
        case RECEIVING_CUSTOMER_PARAMS:
            return {
                ...state,
                isReceiving: true
            };
        case RECEIVED_ERROR_RESPONSE:
            return {
                ...state,
                isReceiving: false,
                errorMessage: action.payload,
            };
        default:
            return state;
    }
}
