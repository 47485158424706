import React from 'react';
import { Box, Typography } from '@mui/material';
import { SentimentDissatisfied } from '@mui/icons-material';

const NoDataFound = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            textAlign="center"
            sx={{ p: 4 }}
        >
            <SentimentDissatisfied sx={{ fontSize: 80, color: 'grey.500' }} />
            <Typography variant="h6" color="textSecondary" mt={2}>
                No Data Found
            </Typography>
        </Box>
    );
};

export default NoDataFound;