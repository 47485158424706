import React, { useEffect } from "react";

import routes from "./routes";
import useJumboRoutes from "@jumbo/hooks/useJumboRoutes";
import { differenceInMinutes } from 'date-fns';
import { useNavigate } from "react-router-dom";

const AppRoutes = () => {
    const navigate = useNavigate();
    const appRoutes = useJumboRoutes(routes);
    
    return (
        <React.Fragment>
            {
                appRoutes
            }
        </React.Fragment>
    );
};
export default AppRoutes;
