import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import ReportsList from "./components/ReportsList";
import ReportsProvider from "./ReportsProvider";
import SearchReportsForm from "./components/SearchReportsForm";
import { searchReports, getReportsParams, getReports } from "../../redux/actions/reports";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";


const isObject = (variable) => {
    return variable !== null && typeof variable === 'object';
}

const Reports = () => {

    const { authUser } = useJumboAuth();

    console.log(authUser);
    const dispatch = useDispatch();
    const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);

    const reports_params = useSelector(state => state.reports.reports_params);
    const reports_data = useSelector(state => state.reports.reports_data);
    const isReceiving = useSelector(state => state.reports.isReceiving);
    const errorMessage = useSelector(state => state.reports.errorMessage);


    useEffect(() => {
        if (reports_params) {
            dispatch(getReportsParams({
                user_email: authUser.user_email,
                user_id: authUser.u_user_id,
                role: authUser.role,
                created_by: authUser.user_email,
                location: authUser.locationData
            }, authUser))
        }
    }, [dispatch]);

    const [searchCreteria, setSearchCreteria] = useState({});
    const handleSearchReports = useCallback(async (data) => {


        console.log(authUser);
        Object.keys(data).forEach(key => {
            if (!data[key]) {
                delete data[key];
            }
        });
        data.user_email = authUser.user_email;
        data.user_id = authUser.u_user_id;
        data.role = authUser.role;
        data.created_by = authUser.u_user_id;
        data.location = authUser.locationData;
        await dispatch(getReports(data));
        setSearchCreteria(data);

    }, [dispatch]);


    return (

        <ReportsProvider>
            <SearchReportsForm
                onSearchReports={handleSearchReports}
                reports_params={reports_params}
            />
            {isReceiving ? (
                <CircularProgress />
            ) : (
                <ReportsList
                    reports_data={reports_data}
                    reports_params={reports_params}
                    setIsGroupModalOpen={setIsGroupModalOpen}
                    searchCreteria={searchCreteria}
                    initialGroupModalState={isGroupModalOpen}
                />
            )
            }

        </ReportsProvider>
    );
};

export default Reports;
