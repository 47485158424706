import React, { useState, useRef, useEffect } from 'react';
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Box,
    FormHelperText,
    Button,
    InputAdornment,
    Typography,
    Modal
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { personalDetailsSchema } from '../../../../FormValidation';
import * as Yup from 'yup';
import { format, parse } from 'date-fns';
import { config } from '../../../../../config/config'
import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

export const PersonalDetails = ({
    verifyUserOrg,
    org,
    validateLicenceNo,
    licence_details,
    isFetching,
    isValidatingLicence,
    activeStep,
    initialFormData,
    handleNext,
    handleBack,
    clearOrg,
    noOfSteps
}) => {


    const idtypes = [
        { label: "National ID", value: "national_id" },
        { label: "Passport", value: "passport" }
    ]

    const gender_options = [
        { label: "Male", value: "m" },
        { label: "Female", value: "f" }
    ]

    const roles = [
        { label: "System Admin", value: "sa-system" },
        { label: "Report Manager", value: "report-mng" },
        { label: "Report Client", value: "report-client" }
    ]

    const [values, setValues] = useState({
        firstname: '',
        lastname: '',
        role: '',
        idtype: '',
        idnumber: '',
        id_idFile: '',
        gender: '',
        id_file_name: '',
        dateofbirth: '',
    });
    const [errors, setErrors] = useState({});
    const [openModal, setOpenModal] = useState(false); // Modal state
    const [showSearchOrg, setShowSearchOrg] = useState(false); // Modal state
    const idFileInputRef = useRef(null);

    var v_field39 = org?.v_field39;
    var v_field48 = org?.v_field48;
    
    var v_validationField39 = licence_details?.v_field39;
    var v_validationField48 = licence_details?.v_field48;




    useEffect(() => {
        if (v_validationField39 !== "000") {
            setErrors(prev => ({ ...prev, 'licence_number': v_validationField48 }));
        }
    }, [v_validationField39, v_validationField48]);

    const handleChangeRole = (event) => {


        clearOrg();
        handleChangeOrgID({
            target: {
                name: 'org_number',
                value: ''
            }
        })
        const { name, value } = event.target;
        ////console.log(v_field39, value, showSearchOrg);
        if (value === "report-mng") {
            setShowSearchOrg(true);
        } else {
            setShowSearchOrg(false);
        }
        setValues(prev => ({ ...prev, [name]: value }));

        // Validate field value on change
        validateField(name, value);
    };



    const handleVerify = () => {
        verifyUserOrg(values.org_number);
    }


    const handleChangeLicence = (event) => {
        const { name, value } = event.target;
        setValues(prev => ({ ...prev, [name]: value }));
        validateField(name, value);
        if (value.length > 8 && value.length < 12) {
            validateLicenceNo(value);
        }

    };

    const handleChangeOrgID = (event) => {
        clearOrg();
        const { name, value } = event.target;

        ////console.log(name, value)
        setValues(prev => ({ ...prev, [name]: value }));
        // Validate field value on change
        validateField(name, value);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues(prev => ({ ...prev, [name]: value }));

        // Validate field value on change
        validateField(name, value);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        ////console.log(file);
        if (file) {
            setValues(prev => ({ ...prev, id_idFile: file, id_file_name: file.name }));
            setErrors(prev => ({ ...prev, id_idFile: undefined }));
        }
    };

    const handleTextFieldClick = (event) => {
        // Ignore click events from buttons inside the TextField
        if (event.target.closest('button')) return;

        idFileInputRef.current.click();
    };

    const handleDateChange = (date) => {
        let formatedDate = format(date, 'yyyy-MM-dd');
        handleChange({
            target: {
                name: 'dateofbirth',
                value: formatedDate,
            },
        });
    };


    const handleNextClick = async (event) => {
        try {
            const label = event.target.innerText.toLowerCase();
            await personalDetailsSchema.validate(values, { abortEarly: false });
            handleNext({ personalDetails: values }, label, values.role); // Proceed to the next step if there are no validation errors
        } catch (validationErrors) {
            ////console.log(validationErrors);
            const errorMessages = validationErrors.inner.reduce((acc, error) => {
                acc[error.path] = error.message;
                return acc;
            }, {});
            setErrors(errorMessages);
        }
    };

    const handleBackClick = async () => {
        handleBack({ personalDetails: values });
    };

    const validateField = async (name, value) => {
        try {
            const schema = Yup.object().shape({
                [name]: personalDetailsSchema.fields[name]
            });
            await schema.validate({ [name]: value });
            setErrors(prev => ({ ...prev, [name]: undefined }));
        } catch (error) {
            setErrors(prev => ({ ...prev, [name]: error.message }));
        }
    };

    const handleFocus = (event) => {
        const { name } = event.target;
        setErrors(prev => ({ ...prev, [name]: undefined }));
    };

    const handleViewFile = () => {
        if (values.id_idFile) {
            setOpenModal(true); // Open modal
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false); // Close modal
    };

    const handleClearFile = () => {
        setValues(prev => ({
            ...prev,
            id_idFile: null,
            id_file_name: null
        }));
    };

    const getFileName = (url) => {
        if (url) {
            return new URL(url).pathname.split('/').pop();
        }
        return null;

    }

    const getFileURL = () => {
        let id_idFile = null;
        if (values.id_idFile instanceof File) {
            id_idFile = values.id_idFile ? URL.createObjectURL(values.id_idFile) : '';
        } else {
            id_idFile = values.id_idFile ? config.base_url + values.id_idFile.replace('/srv/uploads', "/files") : "";
        }

        return id_idFile;
    }

    const fileURL = getFileURL();


    return (
        <Box sx={{ position: 'relative', p: 3, backgroundColor: 'background.paper', borderRadius: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={showSearchOrg ? 3.5 : 5}>
                    <TextField
                        name="firstname"
                        fullWidth
                        margin="normal"
                        label="First Name"
                        value={values.firstname}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        helperText={errors.firstname}
                        error={Boolean(errors.firstname)}
                    />
                </Grid>
                <Grid item xs={12} sm={showSearchOrg ? 3.5 : 5}>
                    <TextField
                        name="lastname"
                        fullWidth
                        margin="normal"
                        label="Last Name"
                        value={values.lastname}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        helperText={errors.lastname}
                        error={Boolean(errors.lastname)}
                    />
                </Grid>
                {roles &&
                    <Grid item xs={12} sm={2}>
                        <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel>Role</InputLabel>
                            <Select
                                name="role"
                                value={values.role}
                                onChange={handleChangeRole}
                                onFocus={handleFocus}
                                label="Role"
                                error={Boolean(errors.role)}
                            >
                                {roles.map((role) => (
                                    <MenuItem key={role.value} value={role.value}>
                                        {role.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.role}</FormHelperText>
                        </FormControl>
                    </Grid>
                }

                {showSearchOrg &&
                    <Grid item xs={12} sm={3}>
                        <TextField
                            name="org_number"
                            fullWidth
                            margin="normal"
                            label="Organization ID"
                            value={values.org_number}
                            onChange={handleChangeOrgID}
                            onFocus={handleFocus}
                            helperText={errors.org_number}
                            error={Boolean(errors.org_number)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <LoadingButton
                                            variant="outlined"
                                            size="large"
                                            color={v_field39 == "000" ? "success" : "error"}
                                            loading={isFetching}
                                            onClick={handleVerify}
                                        >
                                            {v_field39 == "000" ? "Verified" : "Verify"}
                                        </LoadingButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                }
                <Grid item xs={12} sm={2}>
                    <FormControl variant="outlined" fullWidth margin="normal">
                        <InputLabel>ID Type</InputLabel>
                        <Select
                            name="idtype"
                            value={values.idtype}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            label="ID Type"
                            error={Boolean(errors.idtype)}
                        >
                            {idtypes.map((idtype) => (
                                <MenuItem key={idtype.value} value={idtype.value}>
                                    {idtype.label}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors.idtype}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={showSearchOrg ? 3 : 4}>
                    <TextField
                        name="idnumber"
                        fullWidth
                        margin="normal"
                        label="ID Number"
                        value={values.idnumber}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        helperText={errors.idnumber}
                        error={Boolean(errors.idnumber)}
                    />
                </Grid>
                {showSearchOrg &&
                    <Grid item xs={12} sm={showSearchOrg ? 3 : 5}>
                        <TextField
                            name="licence_number"
                            fullWidth
                            margin="normal"
                            label="Licence No."
                            value={values.licence_number}
                            onChange={handleChangeLicence}
                            onFocus={handleFocus}
                            helperText={errors.licence_number}
                            error={Boolean(errors.licence_number)}
                            InputProps={{
                                endAdornment: isValidatingLicence ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null,
                            }}
                        />
                    </Grid>
                }
                <Grid item xs={12} sm={showSearchOrg ? 4 : 6}>
                    <input
                        type="file"
                        name="id_idFile"
                        ref={idFileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="ID File"
                        onClick={handleTextFieldClick}
                        value={values.id_file_name || getFileName(fileURL)}
                        InputProps={{
                            readOnly: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    {values.id_idFile && (
                                        <>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleViewFile}
                                                style={{ marginRight: '8px' }}
                                            >
                                                View
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={handleClearFile}
                                            >
                                                Clear
                                            </Button>
                                        </>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                        helperText={errors.id_idFile}
                        error={Boolean(errors.id_idFile)}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl variant="outlined" fullWidth margin="normal">
                        <InputLabel>Gender</InputLabel>
                        <Select
                            name="gender"
                            value={values.gender}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            label="Gender"
                            error={Boolean(errors.gender)}
                        >
                            {gender_options.map((gender) => (
                                <MenuItem key={gender.value} value={gender.value}>
                                    {gender.label}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors.gender}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Date of Birth"
                            inputFormat="yyyy-MM-dd"
                            value={values.dateofbirth}
                            onChange={(date) => handleDateChange(date)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{ shrink: true }}
                                    helperText={errors.dateofbirth}
                                    error={Boolean(errors.dateofbirth)}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="user_email"
                        fullWidth
                        margin="normal"
                        label="User Email"
                        value={values.user_email}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        helperText={errors.user_email}
                        error={Boolean(errors.user_email)}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="mobilenumber"
                        value={values.mobilenumber}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        label="Mobile Number"
                        helperText={errors.mobilenumber}
                        error={Boolean(errors.mobilenumber)}
                    />
                </Grid>
            </Grid>

            <Box display="flex" justifyContent="space-between" mt={2}>

                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleBackClick}
                    disabled={activeStep === 0}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNextClick} // Handle Next button click
                >
                    {activeStep == noOfSteps - 1 || values.role !== "sa-system" ? 'Finish' : 'Next'}
                </Button>

            </Box>

            {/* Modal for Viewing File */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="file-viewer-modal"
                aria-describedby="file-viewer-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" component="h2" gutterBottom>
                        {values.id_file_name}
                    </Typography>
                    {values.id_idFile && (
                        <iframe
                            src={fileURL}
                            width="100%"
                            height="500px"
                            title="File Preview"
                            style={{ border: 'none' }}
                        />
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCloseModal}
                        style={{ marginTop: '16px' }}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
        </Box>


    );
};
