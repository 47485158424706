import { RECEIVED_DATA_SUCCESS, RECEIVING_DATA, RECEIVED_LOCATION_DATA, RECEIVING_LOCATION_DATA } from './../actions/dashboard';

const defaultState = {
    data: {
        EVENTS: [],
        TOTALS: [],
        MONTHLY: [],
        COMPARISONS: [],
        REPAYMENT: [],
        PAR: [],
    },
    locationData: [],
    isReceiving: {
        EVENTS: false,
        TOTALS: false,
        MONTHLY: false,
        COMPARISONS: false,
        REPAYMENT: false,
        PAR: false,
    },
    isLoadingLocation: false,
    isDeleting: false,
    idToDelete: null
};

export default function deductionReducer(state = defaultState, action) {
    switch (action.type) {
        case RECEIVED_DATA_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.dataKey]: action.payload,
                },
                isReceiving: {
                    ...state.isReceiving,
                    [action.dataKey]: false,
                },
            };

        case RECEIVING_DATA:
            return {
                ...state,
                isReceiving: {
                    ...state.isReceiving,
                    [action.dataKey]: true,
                },
            };

        case RECEIVED_LOCATION_DATA:
            return {
                ...state,
                locationData: action.payload,
                isLoadingLocation: false,
            };

        case RECEIVING_LOCATION_DATA:
            return {
                ...state,
                isLoadingLocation: true,
            };

        default:
            return state;
    }
}
