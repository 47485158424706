import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SIGNUP_REQUEST,
    RECEIVE_SIGNUP,
    SIGNUP_FAILED,
    VERIFY_FAILURE,
    VERIFY_SUCCESS,
    VERIFY_REQUEST,
    LOGOUT_SUCCESS,
    CLEAR_ORG,
    LICENCE_VALIDATION_FAILURE,
    LICENCE_VALIDATION_REQUEST,
    LICENCE_VALIDATION_SUCCESS
} from './../actions/login';

export default function login(state = {
    isFetching: false,
    isValidatingLicence: false,
    errorMessage: '',
    authAttempted: false,
    user: {},
    licence_details: {},
    org: {}

}, action) {
    switch (action.type) {
        case LOGIN_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                authAttempted: true,
                errorMessage: '',
                user: ''
            });
        case SIGNUP_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                errorMessage: '',
                user: ''
            });
        case SIGNUP_FAILED:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.payload,
                user: ''
            });

        case LICENCE_VALIDATION_REQUEST:
            return Object.assign({}, state, {
                isValidatingLicence: true,
                errorMessage: '',
            });

        case LICENCE_VALIDATION_SUCCESS:
            return Object.assign({}, ...state, {
                isValidatingLicence: false,
                licence_details: action.payload,
                errorMessage: '',
            });

        case LICENCE_VALIDATION_FAILURE:
            return Object.assign({}, state, {
                isValidatingLicence: false,
                licence_details: action.payload,
            });

        case VERIFY_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                errorMessage: '',
            });

        case VERIFY_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                org: action.payload,
                errorMessage: '',
            });

        case VERIFY_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.payload,
            });

        case RECEIVE_SIGNUP:
            return Object.assign({}, state, {
                isFetching: false,
                user: action.payload,
                errorMessage: '',
            });
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: '',
                authAttempted: true,
                user: action.payload,
            });
        case LOGIN_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.payload,
                authAttempted: true,
                user: ''
            });

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: '',
                user: ''
            });

        case CLEAR_ORG:
             return Object.assign({}, state, {
                isFetching: false,
                errorMessage: '',
                user: '',
                org: '',
            });

        default:
            return state;
    }
}
