import React from 'react';
import ReportRequestsContext from "./ReportRequestsContext";
import {REPORTS_ACTIONS} from "./utils/constants";

const init = (appState) => appState;

const reportsReducer = (state, action) => {
    switch (action.type) {
        case REPORTS_ACTIONS.SET_SELECTED_REQUESTS:
            return {
                ...state,
                selectedReports: action?.payload
            };
        case REPORTS_ACTIONS.SET_REPORTS_LIST_REFRESH:
            return {
                ...state,
                refreshReportsList: action.payload,
            };
        case REPORTS_ACTIONS.SET_LABELS_LIST_REFRESH:
            return {
                ...state,
                refreshLabelsList: action.payload,
            };
        default:
            return state;
    }
};

const ReportRequestsProvider = ({children}) => {
    const [reports, setReports] = React.useReducer(reportsReducer, {
        selectedReports: [],
        refreshReportsList: false,
        refreshLabelsList: false,
    }, init);

    const setSelectedReports = React.useCallback((reports) => {
        setReports({type: REPORTS_ACTIONS.SET_SELECTED_REQUESTS, payload: reports});
    }, [setReports]);

    const setReportsListRefresh = React.useCallback((refresh) => {
        setReports({type: REPORTS_ACTIONS.SET_REPORTS_LIST_REFRESH, payload: refresh})
    }, [setReports]);

    const setLabelsListRefresh = React.useCallback((refresh) => {
        setReports({type: REPORTS_ACTIONS.SET_LABELS_LIST_REFRESH, payload: refresh})
    }, [setReports]);

    const contextValue = React.useMemo(() => ({
        ...reports,
        setSelectedReports: setSelectedReports,
        setReportsListRefresh: setReportsListRefresh,
        setLabelsListRefresh: setLabelsListRefresh,
    }), [reports]);
    return (
        <ReportRequestsContext.Provider value={contextValue}>
            {children}
        </ReportRequestsContext.Provider>
    );
};

export default ReportRequestsProvider;
