import React, { useEffect } from 'react';
import { GoogleMap, InfoWindow, Marker, useLoadScript, MarkerClusterer } from "@react-google-maps/api";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { useTranslation } from "react-i18next";
import { config } from '../../../../config/config';
import { useSelector, useDispatch } from 'react-redux';
import { getClientLocationData } from "../../../../redux/actions/dashboard";
import { CircularProgress, Box } from "@mui/material";


const userString = localStorage.getItem('user');
const authUser = userString ? JSON.parse(userString) : undefined;

const MembersOnMap = () => {
    const { t } = useTranslation();
    const [selectedLocation, setSelectedLocation] = React.useState(null);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: config.googleAPIKey,
    });

    const handleMarkerClick = (location) => {
        setSelectedLocation(location);
    };


    const dispatch = useDispatch();


    const clientLocationData = useSelector(state => state.dashboard.locationData);
    const isLoadingLocation = useSelector(state => state.dashboard.isLoadingLocation)
    const { v_gps_locations } = clientLocationData;

    useEffect(() => {
        if (authUser && !isLoadingLocation) {
            dispatch(getClientLocationData(authUser));
        }
    }, [dispatch, authUser]);


    return (
        <React.Fragment>

            <JumboDemoCard title={t("widgets.subheader.memberGeo")} wrapperSx={{ pt: 0, backgroundColor: 'background.paper' }}>


                {isLoadingLocation ? (
                    <Box display="flex" justifyContent="top" alignItems="left" height="100vh">
                        <CircularProgress />
                    </Box>
                ) : (

                    isLoaded && (
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '400px' }}
                            center={v_gps_locations ? v_gps_locations[0].position : { lat: -1.2921, lng: 36.8219 }}
                            zoom={13}
                        >

                            <MarkerClusterer
                                enableRetinaIcons
                                averageCenter
                                gridSize={60}
                            >
                                {(clusterer) =>
                                    v_gps_locations.map((marker, index) => (
                                        <Marker
                                            position={marker.position}
                                            key={index}
                                            clusterer={clusterer}
                                        />
                                    ))
                                }
                            </MarkerClusterer>


                            {selectedLocation && (
                                <InfoWindow
                                    position={selectedLocation.position}
                                    onCloseClick={() => setSelectedLocation(null)}
                                >
                                    <div>{selectedLocation.name}</div>
                                </InfoWindow>
                            )}
                        </GoogleMap>
                    )
                )}
            </JumboDemoCard>
        </React.Fragment>
    );
};

export default MembersOnMap;
