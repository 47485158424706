import React, { useState } from 'react';
import Avatar from "@mui/material/Avatar";
import { ListItemIcon, ListItemText, Typography, Menu, MenuItem } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { getAssetPath } from "../../../utils/appHelpers";
import { ASSET_AVATARS } from "../../../utils/constants/paths";

const AuthUserDropdown = () => {
  const { authUser, logout, setAuthData } = useJumboAuth();
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  const profile_pic = getAssetPath(`${ASSET_AVATARS}/avatar3.jpg`, `60x60`);

  const organizations = authUser ? authUser.orgs : []

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(organizations?.[0]); // Default to first organization

  const handleOrgClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOrgClose = (org) => {
    setAnchorEl(null);
    setSelectedOrg(org); // Update selectedOrg state

    // Update the authUser object with the selectedOrgID
    const authData = {
      ...authUser,
      selectedOrgID: org.org_id
    }
    //console.log(authData);
    setAuthData(authData);

    console.log(`Switched to organization: ${org.organisation_name}`);
  };

  return (

    <>

      {authUser &&
        <JumboDdPopover
          triggerButton={
            <Avatar
              src={profile_pic}
              sizes={"small"}
              sx={{ boxShadow: 25, cursor: 'pointer' }}
            />
          }
        >
          <Div sx={{ width: '300px', display: 'flex', alignItems: 'center', flexDirection: 'column', p: theme => theme.spacing(2.5) }}>
            <Avatar src={profile_pic} alt={authUser.firstname} sx={{ width: 60, height: 60, mb: 2 }} />
            <Typography variant={"h5"}>{authUser.firstname + " " + authUser.lastname}</Typography>
            <Typography variant={"h5"}>{authUser.role}</Typography>
            <Typography variant={"body1"} color="text.secondary">{authUser.user_email}</Typography>
          </Div>
          <Divider />
          <nav>
            <List disablePadding sx={{ pb: 1 }}>
              {selectedOrg &&
                <>
                  <ListItemButton onClick={handleOrgClick} sx={{ justifyContent: 'center' }}>
                    <ListItemText primary={selectedOrg.organisation_name} sx={{ textAlign: 'center' }} />
                  </ListItemButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                  >
                    {organizations.map((org) => (
                      <MenuItem key={org.org_id} onClick={() => handleOrgClose(org)}>
                        {org.organisation_name}
                      </MenuItem>
                    ))}
                  </Menu>
                  <Divider />
                </>
              }
              <ListItemButton onClick={logout} sx={{ justifyContent: 'center' }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <LogoutIcon sx={{ mr: 1 }} />
                      Logout
                    </Typography>
                  }
                  sx={{ textAlign: 'center' }}
                />
              </ListItemButton>
            </List>
          </nav>
        </JumboDdPopover>
      }

    </>
  );
};

export default AuthUserDropdown;
