export const config = {
  chartColors: {
    blue: "#1870DC",
    green: "#58D777",
    orange: "#f0af03",
    red: "#F45722",
    purple: "#474D84",
    dark: "#040620",
    teal: "#14d3d3",
    pink: "#e671b8",
    gray: "#d6dee5",
    default: "#8cbf26",
    textColor: "#e0e0e1",
    gridLineColor: "#040620",
    violet_deep: "#1b1e3c"
  },
  hostApi: "https://localhost",
  googleAPIKey: "AIzaSyDTjE35Yw7iWOIVFTyvwxPewYoSxuhvp5Y",
  locationAPI: "https://maps.googleapis.com/maps/api/geocode/json?",
  xcor_proxy: "https://apis.windeal.co.ke/proxy",
  base_url: "https://apis.windeal.co.ke",
  remote: "https://apis.windeal.co.ke/postData",
  remoteRoot: "https://apis.windeal.co.ke/windeal/",
  remoteUpload: "https://apis.windeal.co.ke/uploadfile",
  rpcQueue: "thirdday",
  isBackend: true,
  headers: { "Content-Type": "application/json", "x-api-key": "qyfCv4sBd0fcquNZijY9nhN7szU4a7KS" }
}