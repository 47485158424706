import {
    RECEIVED_GROUPS,
    RECEIVING_GROUPS,
    RECEIVED_GROUP,
    RECEIVING_GROUP,
    RECEIVED_GROUP_PARAMS,
    RECEIVING_GROUPS_PARAMS,
    RECEIVED_ERROR_RESPONSE
} from '../actions/groupApp';

const defaultState = {
    group_data: {},
    group_params: {},
    errorMessage: {},
    isReceiving: false,
};

export default function groupAppReducer(state = defaultState, action) {
    switch (action.type) {
        case RECEIVED_GROUP_PARAMS:
            return {
                ...state,
                group_params: action.payload,
                isReceiving: false,
            };
        case RECEIVED_GROUPS:
            return {
                ...state,
                group_data: action.payload,
                isReceiving: false,
            };
        case RECEIVED_GROUP:
            return {
                ...state,
                group_data: action.payload,
                isReceiving: false,
            };
        case RECEIVING_GROUP:
        case RECEIVING_GROUPS:
        case RECEIVING_GROUPS_PARAMS:
            return {
                ...state,
                isReceiving: true
            };
        case RECEIVED_ERROR_RESPONSE:
            return {
                ...state,
                isReceiving: false,
                errorMessage: action.payload,
            };
        default:
            return state;
    }
}
