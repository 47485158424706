import { config } from '../../config/config';

export const RECEIVED_DATA_SUCCESS = 'RECEIVED_DATA_SUCCESS';
export const RECEIVING_DATA = 'RECEIVING_DATA';
export const RECEIVED_RESPONSE = 'RECEIVED_RESPONSE';
export const RECEIVED_LOCATION_DATA = 'RECEIVED_LOCATION_DATA';
export const RECEIVING_LOCATION_DATA = 'RECEIVING_LOCATION_DATA';

export function getDashBoardData(request, user) {


    return (dispatch) => {

        dispatch(receivingData());


        request.rpcQueue = config.rpcQueue;
        request.menu = "dashboard";
        request.method = "getanalytics";
        request.created_by = user.username;
        request.location = user.locationData;


        ////console.log(request);

        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };
        ////////console.log(requestOptions);
        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                let result = JSON.parse(data.result);
                //////console.log(result);
                if (result.v_field39 === "000") {
                    dispatch(receiveDataSuccess(result, request.action));
                } else {
                    dispatch(receivedResponse());
                }
            }).catch((error) => {
                //////console.log(error)
            });

    };
}

export function getClientLocationData(user) {


    return (dispatch) => {

        dispatch(receivingLocationData());

        let request = {
            rpcQueue: config.rpcQueue,
            menu: "dashboard",
            method: "getLocationData",
            created_by: user.username,
            location: user.locationData
        }


        const requestOptions = {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(request)
        };
        ////////console.log(requestOptions);
        fetch(config.remote, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                let result = JSON.parse(data.result);
                //////console.log(result);
                if (result.v_field39 === "000") {
                    dispatch(receiveLocationData(result));
                } else {
                    dispatch(receivedResponse());
                }
            }).catch((error) => {
                ////console.log(error)
            });
    };
}

export function receiveDataSuccess(payload, action) {
    ////////console.log(payload)
    return {
        type: RECEIVED_DATA_SUCCESS,
        payload,
        dataKey: action
    }
}

export function receiveLocationData(payload) {
    return {
        type: RECEIVED_LOCATION_DATA,
        payload
    }
}

export function receivingLocationData() {
    return {
        type: RECEIVING_LOCATION_DATA
    }
}


export function receivingData() {
    return {
        type: RECEIVING_DATA,
    }
}

export function receivedResponse() {
    return {
        type: RECEIVED_RESPONSE,
    }
}