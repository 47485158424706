import React, { useState, useEffect } from 'react';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import { useTranslation } from "react-i18next";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useSelector, useDispatch } from 'react-redux';
import {
    DialogTitle,
    DialogContent,
    Dialog,
    TextField,
    Button,
    Stack,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box
} from '@mui/material';
import ReportItem from './ReportForm/ReportItem';

const SearchReportsForm = ({ onSearchReports, reports_params }) => {

    const { t } = useTranslation();
    const Swal = useSwalWrapper();

    const [values, setValues] = useState({
        report_name: '',
        organisation_name: ''
    });
    const [resetValues, setResetValues] = useState(false);
    const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
    const isReceivingReports = useSelector(state => state.reports.isReceivingReports);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues(prev => ({ ...prev, [name]: value }));
    };

    const handleSearchReports = async () => {
        await onSearchReports(values);
    };

    const handleReset = () => {
        setValues({
            report_name: '',
            organisation_name: ''
        });
    }
    const handleCreateCustomer = async () => {
        setIsCustomerModalOpen(true);
    }

    const handleCustomerCloseModal = () => {
        setIsCustomerModalOpen(false);
    };

    return (
        <Div
            sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2
            }}
        >
            <JumboDemoCard title={"Search Report"} wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>
                <Box sx={{ position: 'relative', p: 3, backgroundColor: 'background.paper', borderRadius: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="report_name"
                                fullWidth
                                margin="normal"
                                label="Report Name"
                                value={values.report_period}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="organisation_name"
                                fullWidth
                                margin="normal"
                                label="Organisation Name"
                                value={values.organisation_name}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Stack direction={"row"} spacing={2}>
                                <LoadingButton
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isReceivingReports}
                                    onClick={handleSearchReports}
                                >
                                    Search
                                </LoadingButton>

                            </Stack>
                        </Grid>

                    </Grid>

                </Box>


                <Dialog
                    open={isCustomerModalOpen}
                    onClose={handleCustomerCloseModal}
                    fullWidth
                    maxWidth="xl"
                    PaperProps={{
                        style: {
                            height: 'auto',
                            width: '80%', // Customize the width as needed
                            maxWidth: 'none' // Ensure it respects the custom width
                        }
                    }}
                >
                    <DialogTitle>Add New Customer</DialogTitle>

                    <DialogContent>
                        <ReportItem
                            reports_params={reports_params}
                            setIsCustomerModalOpen={setIsCustomerModalOpen}
                            customer={{}}
                            isNewCustomer={true}
                        />
                    </DialogContent>

                </Dialog>
            </JumboDemoCard>
        </Div>
    );
};


export default SearchReportsForm;
