import React from "react";
import PropTypes from 'prop-types';
import {Card, CardContent, CardHeader, Typography} from "@mui/material";
import CodeIcon from '@mui/icons-material/Code';
import CloseIcon from '@mui/icons-material/Close';
import JumboCodeBlock from "../JumboCodeBlock";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition({children, ...restProps}, ref) {
    return (
        <Slide direction="up" ref={ref} {...restProps}>
            {children}
        </Slide>
    );
});

const JumboDemoCard = ({title, subheader, children, demoCode, noWrapper, wrapperSx, sx}) => {
    const [open, setOpen] = React.useState(false);
     
    const handleClickOpen = React.useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <React.Fragment>
            <Card
                sx={sx}
            >
                <CardHeader
                    title={
                        title && (
                            <Typography variant={"h4"} mb={0}>{title}</Typography>
                        )
                    }
                    subheader={
                        subheader && (
                            <Typography sx={{mt: 1, color: "text.secondary"}}>{subheader}</Typography>
                        )
                    }
                   
                />
                {
                    noWrapper ? children :
                        <CardContent
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: theme => theme.palette.action.hover,
                                ...wrapperSx,
                            }}
                        >
                            {children}
                        </CardContent>
                }
            </Card>
            
               
                
        </React.Fragment>
    )
};

JumboDemoCard.propTypes = {
    title: PropTypes.node,
    subheader: PropTypes.node,
    children: PropTypes.node,
    demoCodeFile: PropTypes.string,
};

export default JumboDemoCard;
